import api from "./api";
import { format } from "date-fns";

export const addGame = async (data) => {
  const response = await api.post(
    "api/admin/games/createGame",
    JSON.stringify(data)
  );
  // console.log("Response:", response);
  return response;
};

export const updateGame = async (data) => {
  const response = await api.patch(
    `api/admin/games/updateGame/${data._id}`,
    JSON.stringify(data)
  );

  return response;
};

export const getGames = async ({ queryKey }) => {
  const [_, date, selectedLeague] = queryKey;

  // Make the API request
  const response = await api.get(
    `api/admin/games/getAllGames/${selectedLeague}/${date}`
  );

  // Log the response to the console
  // console.log('API Response:', response);

  return response;
};

export const fetchGamesForNext10Days = async (selectedLeague) => {
  // Generate an array of dates from today to the next 10 days
  const dates = Array.from({ length: 11 }, (_, i) => {
    const date = new Date();
    date.setDate(date.getDate() + i);
    return format(date, "MM-dd-yyyy");
  });

  // Fetch data for each date
  const fetchPromises = dates.map((date) =>
    api
      .get(`api/admin/games/getAllGames/${selectedLeague}/${date}`)
      .then((response) => response.data)
      .catch((error) => {
        console.error(`Failed to fetch data for date ${date}:`, error);
        return null;
      })
  );

  // Wait for all requests to complete and filter out any null results
  const gamesData = await Promise.all(fetchPromises);
  const validGames = gamesData
    .filter(Boolean)
    .flatMap(response => response)
    .filter(game => game.league === selectedLeague);

  return validGames;
};

export const getGameById = async (id) => {
  const response = await api.get(`api/getGame/${id}`);

  return response;
};

export const enterGameResults = async (data) => {
  const { game_id, ...rest } = data;
  const response = await api.patch(
    `api/admin/games/updateGame/${game_id}`,
    JSON.stringify(rest)
  );

  return response;
};

export const getGamesByDate = async (date) => {
  const response = await api.get(`api/admin/games/getGameBydate/${date}`);

  return response;
};

export const updateGameFields = async (data) => {
  const response = await api.patch(
    `api/admin/games/updateGameFields/${data._id}`,
    JSON.stringify(data)
  );

  return response;
};

export const checkGameExists = async (games) => {
  const response = await api.post(
    "api/admin/games/checkGameExists",
    JSON.stringify({ games })
  );
  return response.data;
};

export const getSubmittedGames = async () => {
  try {
    const response = await api.get("api/admin/games/getSubmittedGames");
    
    if (!response.data) {
      throw new Error("No data received from server");
    }

    // Check if response has the expected structure
    if (response.data.success === false) {
      throw new Error(response.data.message || "Failed to fetch submitted games");
    }

    return response.data.games; // Return just the games array from the response

  } catch (error) {
    console.error("Error fetching submitted games:", error);
    throw error;
  }
};
