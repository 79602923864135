import React, { useEffect, useState } from "react";
import "./Styles.css";
import { useLeagueContext } from "../LeagueContext";
import { getUserById } from "../../Apis/auth";
import { getGamesPlayedByDateRange } from "../../Apis/predictions";
import { headerOptions } from "./data";
import {
  getStateAbbreviation,
  getCountryCode,
} from "../../utils/stateAbbreviations";

const calculateReg = (row) => {
  return row.result?.endingsPoints?.pickRegulation || 0;
};

const calculateOT = (row) => {
  return row.result?.endingsPoints?.pickOverTime || 0;
};

const calculateSO = (row) => {
  return row.result?.endingsPoints?.pickShootout || 0;
};

const calculateEI = (row) => {
  return row.result?.endingsPoints?.pickExtraInnings || 0;
};

// Utility functions for calculations
const calculateWinStreak = (games) => {
  let maxStreak = 0,
    currentStreak = 0;
  // Sort games by date in ascending order (oldest first)
  const sortedGames = games.sort((a, b) => new Date(a.date) - new Date(b.date));

  sortedGames.forEach((game) => {
    const ml =
      game.result?.vegasOdds?.pickingFavorite ||
      game.result?.vegasOdds?.pickingUnderdog ||
      "-";
    if (ml !== "-" && parseFloat(ml) >= 0) {
      currentStreak++;
      maxStreak = Math.max(maxStreak, currentStreak);
    } else {
      currentStreak = 0;
    }
  });
  return maxStreak;
};

const calculateLossStreak = (games) => {
  let maxStreak = 0,
    currentStreak = 0;
  // Sort games by date in ascending order (oldest first)
  const sortedGames = games.sort((a, b) => new Date(a.date) - new Date(b.date));

  sortedGames.forEach((game) => {
    const ml =
      game.result?.vegasOdds?.pickingFavorite ||
      game.result?.vegasOdds?.pickingUnderdog ||
      "-";
    if (ml === "-" || parseFloat(ml) < 0) {
      currentStreak++;
      maxStreak = Math.max(maxStreak, currentStreak);
    } else {
      currentStreak = 0;
    }
  });
  return maxStreak;
};

const calculateCurrentStreak = (games) => {
  if (games.length === 0) return "";

  // Sort games by date in descending order (most recent first)
  const sortedGames = games.sort((a, b) => new Date(b.date) - new Date(a.date));

  let streak = 0;
  let isWinStreak = true;

  for (const game of sortedGames) {
    const ml = parseFloat(
      game.result?.vegasOdds?.pickingFavorite ||
        game.result?.vegasOdds?.pickingUnderdog
    );

    // Consider it a win if ml is a number (including 0)
    const isWin = !isNaN(ml);

    if (streak === 0) {
      isWinStreak = isWin;
    }

    if (isWin === isWinStreak) {
      streak++;
    } else {
      break;
    }
  }

  return isWinStreak ? `W${streak}` : `L${streak}`;
};

const calculatePointsPercentage = (games, condition) => {
  const totalPoints = games.reduce(
    (acc, game) => acc + (game[condition] || 0),
    0
  );
  const maxPoints = games.length * 100; // Assume max points per condition is 100
  return ((totalPoints / maxPoints) * 100).toFixed(2);
};

const calculateL10 = (games) => {
  const last10Games = games.slice(-10);
  let winCount = 0,
    lossCount = 0;
  last10Games.forEach((game) => {
    if (game.BR > 0) winCount++;
    else lossCount++;
  });
  return `${winCount}/${lossCount}`;
};

const calculateFavoritePointsPercentage = (games) => {
  console.log("Calculating FPTS for games:", games);
  let actualFavoritePoints = 0;
  let totalPossibleFavoritePoints = 0;

  games.forEach((game) => {
    console.log("Processing game:", game);
    const favoritePoints = game.result?.vegasOdds?.pickingFavorite || 0;
    const underdogPoints = game.result?.vegasOdds?.pickingUnderdog || 0;
    console.log(
      "Favorite points:",
      favoritePoints,
      "Underdog points:",
      underdogPoints
    );

    // Determine which team was the favorite
    const favoriteWasHome = favoritePoints > underdogPoints;
    console.log("Favorite was home:", favoriteWasHome);

    // Add to total possible points
    totalPossibleFavoritePoints += Math.max(favoritePoints, underdogPoints);

    // Check if the user picked the favorite and it won
    if (favoriteWasHome) {
      if (game.pick_home >= game.pick_visitor) {
        actualFavoritePoints += favoritePoints;
        console.log("User correctly picked home favorite");
      }
    } else {
      if (game.pick_visitor >= game.pick_home) {
        actualFavoritePoints += underdogPoints;
        console.log("User correctly picked away favorite");
      }
    }
  });

  console.log("Actual favorite points:", actualFavoritePoints);
  console.log("Total possible favorite points:", totalPossibleFavoritePoints);

  // Calculate percentage
  const percentage =
    totalPossibleFavoritePoints > 0
      ? (actualFavoritePoints / totalPossibleFavoritePoints) * 100
      : 0;

  console.log("Calculated percentage:", percentage);

  return percentage.toFixed(2);
};

const calculateUnderdogPointsPercentage = (games) => {
  console.log("Calculating UPTS for games:", games);
  let actualUnderdogPoints = 0;
  let totalPossibleUnderdogPoints = 0;

  games.forEach((game) => {
    console.log("Processing game:", game);
    const favoritePoints = game.result?.vegasOdds?.pickingFavorite || 0;
    const underdogPoints = game.result?.vegasOdds?.pickingUnderdog || 0;
    console.log(
      "Favorite points:",
      favoritePoints,
      "Underdog points:",
      underdogPoints
    );

    // Determine which team was the underdog
    const underdogWasHome = underdogPoints > favoritePoints;
    console.log("Underdog was home:", underdogWasHome);

    // Add to total possible points
    totalPossibleUnderdogPoints += Math.max(favoritePoints, underdogPoints);

    // Check if the user picked the underdog and it won
    if (underdogWasHome) {
      if (game.pick_home >= game.pick_visitor) {
        actualUnderdogPoints += underdogPoints;
        console.log("User correctly picked home underdog");
      }
    } else {
      if (game.pick_visitor >= game.pick_home) {
        actualUnderdogPoints += favoritePoints;
        console.log("User correctly picked away underdog");
      }
    }
  });

  console.log("Actual underdog points:", actualUnderdogPoints);
  console.log("Total possible underdog points:", totalPossibleUnderdogPoints);

  // Calculate percentage
  const percentage =
    totalPossibleUnderdogPoints > 0
      ? (actualUnderdogPoints / totalPossibleUnderdogPoints) * 100
      : 0;

  console.log("Calculated percentage:", percentage);

  return percentage.toFixed(2);
};

// Function to calculate user statistics
const calculateUserStats = (games) => {
  const statsMap = {};

  games.forEach((game) => {
    const userId = game.userId;

    if (!statsMap[userId]) {
      statsMap[userId] = {
        gamesPlayed: 0,
        wins: 0,
        losses: 0,
        points: 0,
        CGS: 0, // Add CGS property to track consecutive predictions
      };
    }

    statsMap[userId].gamesPlayed += 1;
    statsMap[userId].points += game.BR ? parseFloat(game.BR) : 0;

    if (game.BR > 0) {
      statsMap[userId].wins += 1;
    } else {
      statsMap[userId].losses += 1;
    }

    // Increment CGS if the user made a prediction
    if (game.result?.predictionMade) {
      statsMap[userId].CGS += 1;
    } else {
      statsMap[userId].CGS = 0; // Reset CGS if the user didn't make a prediction
    }
  });

  Object.keys(statsMap).forEach((userId) => {
    const userStats = statsMap[userId];
    userStats.winPercentage = (
      (userStats.wins / userStats.gamesPlayed) *
      100
    ).toFixed(2);
    userStats.avgPointsPerGame = (
      userStats.points / userStats.gamesPlayed
    ).toFixed(2);
  });

  return statsMap;
};

const calculateCGS = (games) => {
  let maxCGS = 0;
  let currentCGS = 0;

  // Sort games by date in ascending order
  const sortedGames = games.sort((a, b) => new Date(a.date) - new Date(b.date));

  sortedGames.forEach((game) => {
    // Check if a prediction was made for this game
    const predictionMade =
      (game.pick_visitor !== undefined && game.pick_visitor !== null) ||
      (game.pick_home !== undefined && game.pick_home !== null);

    if (predictionMade) {
      currentCGS++;
      maxCGS = Math.max(maxCGS, currentCGS);
    } else {
      currentCGS = 0;
    }
  });

  return maxCGS;
};

const SeasonalTables = () => {
  const { selectedLeague } = useLeagueContext();
  const [filteredHeaderOptions, setFilteredHeaderOptions] = useState([]);
  const [aggregatedPlayersData, setAggregatedPlayersData] = useState([]);
  const [gameDataMap, setGameDataMap] = useState({});

  useEffect(() => {
    if (selectedLeague && headerOptions[selectedLeague]) {
      setFilteredHeaderOptions(headerOptions[selectedLeague]);
    } else {
      setFilteredHeaderOptions([]);
    }

    fetchAllPlayersData();
  }, [selectedLeague]);

  const fetchAllPlayersData = async () => {
    try {
      const currentDate = new Date();
      const startDate = new Date(currentDate.getFullYear(), 0, 1);
      const endDate = currentDate;

      const formattedStartDate = startDate.toISOString().split("T")[0];
      const formattedEndDate = endDate.toISOString().split("T")[0];

      const response = await getGamesPlayedByDateRange(
        formattedStartDate,
        formattedEndDate
      );
      if (response.data && Array.isArray(response.data.data)) {
        const filteredData = response.data.data.filter(
          (game) => game.league === selectedLeague
        );
        const enhancedData = await Promise.all(
          filteredData.map(async (game) => {
            const userData = await getUserById(game.userId);
            return {
              ...game,
              co: userData.data.country || "-",
              state: userData.data.state || "-",
              city: userData.data.city || "-",
              player: userData.data.leagues[0]?.username || "-",
              team: userData.data.leagues[0]?.team || "-",
              BR:
                game.result?.perfectScore != null
                  ? parseFloat(game.result?.perfectScore).toFixed(2)
                  : "-",
              vegasOdds: game.result?.vegasOdds || {},
            };
          })
        );
        const aggregatedData = aggregatePlayerData(enhancedData);
        setAggregatedPlayersData(aggregatedData);

        const gameDataLookup = {};
        enhancedData.forEach((game) => {
          gameDataLookup[game._id] = game;
        });
        setGameDataMap(gameDataLookup);
      }
    } catch (error) {
      console.error("Error fetching all players data:", error);
    }
  };

  const aggregatePlayerData = (data) => {
    const playerMap = {};

    data.forEach((game) => {
      if (!playerMap[game.userId]) {
        playerMap[game.userId] = {
          co: game.co,
          state: game.state,
          city: game.city,
          player: game.player,
          userId: game.userId,
          team: game.team,
          gamesPlayed: 0,
          totalBR: 0,
          wins: 0,
          losses: 0,
          oneS: 0,
          oneS0: 0,
          oneSW2: 0,
          twoSW2: 0,
          ml: 0,
          spread: 0,
          ou: 0,
          games: [],
          uniqueNights: new Set(),
          totalReg: 0,
          totalOT: 0,
          totalSO: 0,
          cgs: 0,
        };
      }

      const player = playerMap[game.userId];
      player.gamesPlayed++;
      player.totalBR += parseFloat(game.BR) || 0;
      player.uniqueNights.add(
        game.date
          ? game.date.split("T")[0]
          : `game-${game.id || player.gamesPlayed}`
      );

      const ml = parseFloat(
        game.result?.vegasOdds?.pickingFavorite ||
          game.result?.vegasOdds?.pickingUnderdog ||
          0
      );

      if (ml > 0) {
        player.wins += 1;
      } else {
        player.losses += 1;
      }

      player.oneS +=
        (game.result?.accuracyPoints?.home?.p1s || 0) +
        (game.result?.accuracyPoints?.vistor?.p1s || 0);
      player.oneS0 +=
        (game.result?.accuracyPoints?.home?.p1s0 || 0) +
        (game.result?.accuracyPoints?.vistor?.p1s0 || 0);
      player.oneSW2 +=
        (game.result?.accuracyPoints?.home?.p1s2p || 0) +
        (game.result?.accuracyPoints?.vistor?.p1s2p || 0);
      player.twoSW2 +=
        (game.result?.accuracyPoints?.home?.p2s2p || 0) +
        (game.result?.accuracyPoints?.vistor?.p2s2p || 0);
      player.ml += ml;
      player.spread += parseFloat(
        game.result?.vegasOdds?.pickingSpread?.vSpreadPoints ||
          game.result?.vegasOdds?.pickingSpread?.hSpreadPoints ||
          0
      );
      player.ou += parseFloat(
        game.result?.vegasOdds?.pickingOver ||
          game.result?.vegasOdds?.pickingUnder ||
          0
      );
      player.totalReg += calculateReg(game);
      player.totalOT += calculateOT(game);
      player.totalSO += calculateSO(game);
      player.games.push({
        ...game,
        result: game.result || {},
        vegasOdds: game.result?.vegasOdds || {},
      });
    });

    // Calculate CGS for each player after all games have been added
    Object.values(playerMap).forEach((player) => {
      player.cgs = calculateCGS(player.games);
    });

    return Object.values(playerMap);
  };

  const renderColumns = (player) => {
    if (!selectedLeague || !headerOptions[selectedLeague]) return null;

    return headerOptions[selectedLeague].map((header, index) => {
      switch (header) {
        case "CO":
          return (
            <td key={index} className="text-xs font-medium text-center">
              {getCountryCode(player.co) || player.co || "-"}
            </td>
          );
        case "STATE":
          return (
            <td key={index} className="text-xs font-medium text-center">
              {getStateAbbreviation(player.state, player.co) || "-"}
            </td>
          );
        case "CITY":
          return (
            <td key={index} className="text-xs font-medium text-center">
              {player.city || "-"}
            </td>
          );
        case "PLAYER":
          return (
            <td
              key={index}
              className="text-xs font-medium text-center"
              style={{ color: "#dba418", fontSize: "17px" }}
            >
              {player.player || "-"}
            </td>
          );
        case "FAV":
          return (
            <td key={index} className="text-xs font-medium text-center">
              {player.team || "-"}
            </td>
          );
        case "RANK":
          return (
            <td key={index} className="text-xs font-medium text-center">
              {index + 1}
            </td>
          );
        case "WPT":
          return (
            <td key={index} className="text-xs font-medium text-center">
              {((player.wins / player.gamesPlayed) * 100).toFixed(2)}%
            </td>
          );
        case "GP":
          return (
            <td key={index} className="text-xs font-medium text-center">
              {player.gamesPlayed}
            </td>
          );
        case "BR":
          return (
            <td key={index} className="text-xs font-medium text-center">
              {player.totalBR.toFixed(2)}
            </td>
          );
        case "W":
          return (
            <td key={index} className="text-xs font-medium text-center">
              {player.wins}
            </td>
          );
        case "L":
          return (
            <td key={index} className="text-xs font-medium text-center">
              {player.losses}
            </td>
          );
        case "CS":
          return (
            <td key={index} className="text-xs font-medium text-center">
              {calculateCurrentStreak(player.games)}
            </td>
          );
        case "WS":
          return (
            <td key={index} className="text-xs font-medium text-center">
              {calculateWinStreak(player.games)}
            </td>
          );
        case "LS":
          return (
            <td key={index} className="text-xs font-medium text-center">
              {calculateLossStreak(player.games)}
            </td>
          );
        case "1S":
          return (
            <td key={index} className="text-xs font-medium text-center">
              {player.oneS.toFixed(2)}
            </td>
          );
        case "1SO":
          return (
            <td key={index} className="text-xs font-medium text-center">
              {player.oneS0.toFixed(2)}
            </td>
          );
        case "ML":
          return (
            <td key={index} className="text-xs font-medium text-center">
              {player.ml.toFixed(2)}
            </td>
          );
        case "SPRD":
          return (
            <td key={index} className="text-xs font-medium text-center">
              {player.spread.toFixed(2)}
            </td>
          );
        case "O/U":
          return (
            <td key={index} className="text-xs font-medium text-center">
              {player.ou.toFixed(2)}
            </td>
          );
        case "ANP":
          return (
            <td key={index} className="text-xs font-medium text-center">
              {player.gamesPlayed > 0
                ? (player.totalBR / player.gamesPlayed).toFixed(2)
                : "0.00"}
            </td>
          );
        case "AWP":
          return (
            <td key={index} className="text-xs font-medium text-center">
              {player.uniqueNights && player.uniqueNights.size > 0
                ? (player.totalBR / player.uniqueNights.size).toFixed(2)
                : "0.00"}
            </td>
          );
        case "APG":
          return (
            <td key={index} className="text-xs font-medium text-center">
              {player.gamesPlayed > 0
                ? (player.totalBR / player.gamesPlayed).toFixed(2)
                : "0.00"}
            </td>
          );
        case "F":
          return (
            <td key={index} className="text-xs font-medium text-center">
              {calculatePointsPercentage(player.games, "favoritePoints")}
            </td>
          );
        case "U":
          return (
            <td key={index} className="text-xs font-medium text-center">
              {calculatePointsPercentage(player.games, "underdogPoints")}
            </td>
          );
        case "REG":
          return (
            <td key={index} className="text-xs font-medium text-center">
              {player.totalReg.toFixed(2)}
            </td>
          );
        case "OT":
          return (
            <td key={index} className="text-xs font-medium text-center">
              {player.totalOT.toFixed(2)}
            </td>
          );
        case "S/O":
          return (
            <td key={index} className="text-xs font-medium text-center">
              {player.totalSO.toFixed(2)}
            </td>
          );
        case "L10":
          return (
            <td key={index} className="text-xs font-medium text-center">
              {calculateL10(player.games)}
            </td>
          );
        case "CGS":
          return (
            <td key={index} className="text-xs font-medium text-center">
              {player.cgs}
            </td>
          );
        case "FPTS":
          console.log("Player games for FPTS:", player.games);
          const fpts = calculateFavoritePointsPercentage(player.games);
          return (
            <td key={index} className="text-xs font-medium text-center">
              {fpts}%
            </td>
          );
        case "UPTS":
          console.log("Player games for UPTS:", player.games);
          const upts = calculateUnderdogPointsPercentage(player.games);
          return (
            <td key={index} className="text-xs font-medium text-center">
              {upts}%
            </td>
          );
        default:
          return (
            <td key={index} className="text-xs font-medium text-center">
              -
            </td>
          );
      }
    });
  };

  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            {selectedLeague && headerOptions[selectedLeague]
              ? headerOptions[selectedLeague].map((header, index) => (
                  <th
                    key={index}
                    className="text-xs font-medium"
                    style={{
                      color: "#dba418",
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                  >
                    {header}
                  </th>
                ))
              : null}
          </tr>
        </thead>
        <tbody>
          {aggregatedPlayersData.length > 0 ? (
            aggregatedPlayersData.map((player, index) => (
              <tr
                key={index}
                className="h-14 bg-[#181818] text-white separator row-slide-in"
                style={{ animationDelay: `${index * 0.05}s` }}
              >
                {renderColumns(player)}
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={
                  selectedLeague && headerOptions[selectedLeague]
                    ? headerOptions[selectedLeague].length
                    : 1
                }
                className="text-xs font-medium text-center"
              >
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default SeasonalTables;
