import React from "react";
// import { CountryDropdown } from "react-country-region-selector";
import CustomSelect from "../CustomSelect/CustomSelect";
// import { Country } from "country-state-city";
import { Country, State, City } from "country-state-city";

const CountrySelect = ({ value, onChange }) => {
  const result = Country.getAllCountries();

  // Fetch countries
  const countries = Country.getAllCountries(); // List of countries with isoCode (abbreviation)
  // console.log(countries);

  // Fetch states by country code (e.g., US)
  const states = State.getStatesOfCountry("US"); // List of states with isoCode
  // console.log(states);

  // Fetch cities by country and state code
  const cities = City.getCitiesOfState("US", "CA"); // Cities in California
  // console.log(cities);

  return (
    <div className="element-container">
      <div>
        <label>
          <h2
            id="heading"
            className="signup-heading"
            style={{ fontSize: "14px", color: "#FFAE00" }}
          >
            * Country
          </h2>
        </label>
      </div>
      <CustomSelect
        options={result?.map((country) => ({
          label: country?.name,
          value: country.isoCode,
        }))}
        onChange={(e) => onChange(e)}
      />
    </div>
  );
};

export default CountrySelect;
