import React, { useState } from "react";
import Modal from "react-modal";
import displayToast from "../Alert/Alert";
import ModalInput from "./ModalInput";
import {
  sendPasswordResetEmail,
  verifyResetPasswordOTP,
} from "../../Apis/auth";
import logo from "../../assets/StarLogo-Transparent.png";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "8px",
    border: "1px solid #BE8200",
    boxShadow: "0px 4px 40px 0px rgba(190, 130, 0, 0.60)",
    height: "55vh",
    width: "33%",
    background: "#212227",
    padding: "20px",
  },
  overlay: {
    background: "rgba(33, 34, 39, 0.90)",
  },
};
const mobileStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    // marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "8px",
    border: "1px solid #BE8200",
    boxShadow: "0px 4px 40px 0px rgba(190, 130, 0, 0.60)",
    height: "30vh",
    width: "80vw",
    background: "#212227",
    padding: "15px",
  },
  overlay: {
    background: "rgba(33, 34, 39, 0.90)",
  },
};

export const ForgotPasswordModal = (props) => {
  const { modalIsOpen, closeModal } = props;
  const isMobile = window.innerWidth <= 600;

  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [emailError, setEmailError] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError("");
  };

  const handleCodeChange = (e) => {
    setCode(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSendEmail = async () => {
    if (!email) {
      setEmailError("Please enter your email address");
      return;
    } else {
      try {
        const response = await sendPasswordResetEmail(email);
        const data = await response.data;
        console.log(data);
        if (data.success) {
          setIsEmailSent(true);
          setShowCodeInput(true);
          displayToast("Reset code sent to your email", "success");
        } else {
          displayToast("Failed to send reset email", "error");
        }
      } catch (error) {
        displayToast("Failed to send reset email", "error");
      }
    }
  };

  const handleVerifyCode = async () => {
    try {
      const response = await verifyResetPasswordOTP(email, code);
      const data = await response;
      console.log(data);
      if (data.verify) {
        setShowPasswordInput(true);
        displayToast("Code verified successfully", "success");
      } else {
        displayToast(response.message, "error");
      }
    } catch (error) {
      displayToast("Failed to verify code", "error");
    }
  };

  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      displayToast("Passwords do not match", "error");
      return;
    }
    try {
      const response = await verifyResetPasswordOTP(email, code, newPassword);
      if (response.success) {
        displayToast("Password reset successful", "success");
        closeModal();
      } else {
        displayToast(response.message, "error");
      }
    } catch (error) {
      displayToast("Failed to reset password", "error");
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={isMobile ? mobileStyles : customStyles}
      contentLabel="Forgot Password Modal"
    >
      <div style={{ textAlign: "center", padding: "20px" }}>
        <img
          src={logo}
          alt="Logo"
          style={{ width: "250px", marginBottom: "15px" }}
        />
        <h2
          style={{
            color: "#dba418",
            // color: "white",
            padding: "10px 16px",
            borderRadius: "5px",
            border: "none",
          }}
        >
          Forgot Password
        </h2>
        <p
        style={{
          color: "#ffff",
        }}
        >To Reset Your Password, Enter the Email Address You used to Sign up.
</p>
        {isEmailSent === false ? (
          <div>
            <ModalInput
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={handleEmailChange}
            />
            {emailError && <p style={{ color: "red" }}>{emailError}</p>}

            {/* Button Container */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <button
                onClick={closeModal}
                style={{
                  padding: "8px 16px",
                  backgroundColor: "red",
                  color: "#ffff",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  transition: "background-color 0.3s",
                }}
              >
                Close
              </button>
              <button
                onClick={handleSendEmail}
                style={{
                  backgroundColor: "#dba418",
                  color: "Black",
                  padding: "8px 16px",
                  borderRadius: "5px",
                  border: "none",
                  cursor: "pointer",
                  transition: "background-color 0.3s",
                }}
              >
                Send Email
              </button>
            </div>
          </div>
        ) : (
          <div>
            <p>Please enter the code sent to your email: {email}</p>
            <ModalInput
              type="text"
              placeholder="Enter code"
              value={code}
              onChange={handleCodeChange}
            />
            <button onClick={handleVerifyCode}>Verify Code</button>
          </div>
        )}
        {showPasswordInput && (
          <div>
            <ModalInput
              type="password"
              placeholder="New Password"
              value={newPassword}
              onChange={handleNewPasswordChange}
            />
            <ModalInput
              type="password"
              placeholder="Confirm New Password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
            <button onClick={handleResetPassword}>Reset Password</button>
          </div>
        )}
      </div>
    </Modal>
  );
};
