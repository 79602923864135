// src/data.js

export const teamNameMappings = {
  // MLB Teams
  "Arizona Diamondbacks": "Arizona",
  "Atlanta Braves": "Atlanta",
  "Baltimore Orioles": "Baltimore",
  "Boston Red Sox": "Boston",
  "Chicago Cubs": "Chi Cubs",
  "Chicago White Sox": "Chi White Sox",
  "Cincinnati Reds": "Cincinnati",
  "Cleveland Guardians": "Cleveland",
  "Colorado Rockies": "Colorado",
  "Detroit Tigers": "Detroit",
  "Houston Astros": "Houston",
  "Kansas City Royals": "Kansas City",
  "Los Angeles Angels": "LA Angels",
  "Los Angeles Dodgers": "LA Dodgers",
  "Miami Marlins": "Miami",
  "Milwaukee Brewers": "Milwaukee",
  "Minnesota Twins": "Minnesota",
  "New York Mets": "NY Mets",
  "New York Yankees": "NY Yankees",
  "Oakland Athletics": "Oakland",
  "Philadelphia Phillies": "Philadelphia",
  "Pittsburgh Pirates": "Pittsburgh",
  "San Diego Padres": "San Diego",
  "San Francisco Giants": "San Francisco",
  "Seattle Mariners": "Seattle",
  "St. Louis Cardinals": "St. Louis",
  "Tampa Bay Rays": "Tampa Bay",
  "Texas Rangers": "Texas",
  "Toronto Blue Jays": "Toronto",
  "Washington Nationals": "Washington",

  // NBA Teams
  "Atlanta Hawks": "Atlanta",
  "Boston Celtics": "Boston",
  "Brooklyn Nets": "Brooklyn",
  "Charlotte Hornets": "Charlotte",
  "Chicago Bulls": "Chicago",
  "Cleveland Cavaliers": "Cleveland",
  "Dallas Mavericks": "Dallas",
  "Denver Nuggets": "Denver",
  "Detroit Pistons": "Detroit",
  "Golden State Warriors": "Golden State",
  "Houston Rockets": "Houston",
  "Indiana Pacers": "Indiana",
  "Los Angeles Clippers": "LA Clippers",
  "Los Angeles Lakers": "LA Lakers",
  "Memphis Grizzlies": "Memphis",
  "Miami Heat": "Miami",
  "Milwaukee Bucks": "Milwaukee",
  "Minnesota Timberwolves": "Minnesota",
  "New Orleans Pelicans": "New Orleans",
  "New York Knicks": "New York",
  "Oklahoma City Thunder": "Oklahoma",
  "Orlando Magic": "Orlando",
  "Philadelphia 76ers": "Philadelphia",
  "Phoenix Suns": "Phoenix",
  "Portland Trail Blazers": "Portland",
  "Sacramento Kings": "Sacramento",
  "San Antonio Spurs": "San Antonio",
  "Toronto Raptors": "Toronto",
  "Utah Jazz": "Utah",
  "Washington Wizards": "Washington",

  // WNBA Teams
  "Atlanta Dream": "Atlanta",
  "Chicago Sky": "Chicago",
  "Connecticut Sun": "Connecticut",
  "Dallas Wings": "Dallas",
  "Indiana Fever": "Indiana",
  "Las Vegas Aces": "Las Vegas",
  "Los Angeles Sparks": "Los Angeles",
  "Minnesota Lynx": "Minnesota",
  "New York Liberty": "New York",
  "Phoenix Mercury": "Phoenix",
  "Seattle Storm": "Seattle",
  "Washington Mystics": "Washington",

  // NHL Teams
  "Anaheim Ducks": "Anaheim",
  "Arizona Coyotes": "Arizona",
  "Boston Bruins": "Boston",
  "Buffalo Sabres": "Buffalo",
  "Calgary Flames": "Calgary",
  "Carolina Hurricanes": "Carolina",
  "Chicago Blackhawks": "Chicago",
  "Colorado Avalanche": "Colorado",
  "Columbus Blue Jackets": "Columbus",
  "Dallas Stars": "Dallas",
  "Detroit Red Wings": "Detroit",
  "Edmonton Oilers": "Edmonton",
  "Florida Panthers": "Florida",
  "Los Angeles Kings": "LA Kings",
  "Minnesota Wild": "Minnesota",
  "Montréal Canadiens": "Montreal",
  "Nashville Predators": "Nashville",
  "New Jersey Devils": "New Jersey",
  "New York Islanders": "NY Islanders",
  "New York Rangers": "NY Rangers",
  "Ottawa Senators": "Ottawa",
  "Philadelphia Flyers": "Philadelphia",
  "Pittsburgh Penguins": "Pittsburgh",
  "San Jose Sharks": "San Jose",
  "Seattle Kraken": "Seattle",
  "St. Louis Blues": "St. Louis",
  "Tampa Bay Lightning": "Tampa Bay",
  "Toronto Maple Leafs": "Toronto",
  "Utah Hockey Club": "Utah",
  "Vancouver Canucks": "Vancouver",
  "Vegas Golden Knights": "Vegas",
  "Washington Capitals": "Washington",
  "Winnipeg Jets": "Winnipeg",

  // NFL Teams
  "Arizona Cardinals": "Arizona",
  "Atlanta Falcons": "Atlanta",
  "Baltimore Ravens": "Baltimore",
  "Buffalo Bills": "Buffalo",
  "Carolina Panthers": "Carolina",
  "Chicago Bears": "Chicago",
  "Cincinnati Bengals": "Cincinnati",
  "Cleveland Browns": "Cleveland",
  "Dallas Cowboys": "Dallas",
  "Denver Broncos": "Denver",
  "Detroit Lions": "Detroit",
  "Green Bay Packers": "Green Bay",
  "Houston Texans": "Houston",
  "Indianapolis Colts": "Indianapolis",
  "Jacksonville Jaguars": "Jacksonville",
  "Kansas City Chiefs": "Kansas",
  "Las Vegas Raiders": "Las Vegas",
  "Los Angeles Chargers": "LA Chargers",
  "Los Angeles Rams": "LA Rams",
  "Miami Dolphins": "Miami",
  "Minnesota Vikings": "Minnesota",
  "New England Patriots": "New England",
  "New Orleans Saints": "New Orleans",
  "New York Giants": "NY Giants",
  "New York Jets": "NY Jets",
  "Philadelphia Eagles": "Philadelphia",
  "Pittsburgh Steelers": "Pittsburgh",
  "San Francisco 49ers": "San Francisco",
  "Seattle Seahawks": "Seattle",
  "Tampa Bay Buccaneers": "Tampa",
  "Tennessee Titans": "Tennessee",
  "Washington Commanders": "Washington",

  // CFL Teams
  "BC Lions": "BC",
  "Calgary Stampeders": "Calgary",
  "Edmonton Elks": "Edmonton",
  "Saskatchewan Roughriders": "Saskatchewan",
  "Winnipeg Blue Bombers": "Winnipeg",
  "Hamilton Tiger-Cats": "Hamilton",
  "Montreal Alouettes": "Montreal",
  "Ottawa Redblacks": "Ottawa",
  "Toronto Argonauts": "Toronto",

  // UFL Teams
  "Birmingham Stallions": "Birmingham",
  "Houston Roughnecks": "Houston",
  "Memphis Showboats": "Memphis",
  "Michigan Panthers": "Michigan",
  "Arlington Renegades": "Arlington",
  "D.C. Defenders": "D.C.",
  "San Antonio Brahmas": "San Antonio",
  "St. Louis Battlehawks": "St. Louis",
};

export const replaceTeamNames = (gameData) => {
  return {
    ...gameData,
    visitor: teamNameMappings[gameData.visitor] || gameData.visitor,
    home: teamNameMappings[gameData.home] || gameData.home,
  };
};
