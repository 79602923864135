import React, { createContext, useContext, useState } from "react";

const LeagueContext = createContext();

export const useLeagueContext = () => useContext(LeagueContext);

export const LeagueProvider = ({ children }) => {
  const [selectedLeague, setSelectedLeague] = useState(
    localStorage.getItem('selectedLeague') || 'NHL'
  );

  const updateLeague = (newLeague) => {
    setSelectedLeague(newLeague);
    localStorage.setItem('selectedLeague', newLeague);
    // Force a page refresh
    window.location.reload();
  };

  return (
    <LeagueContext.Provider value={{ selectedLeague, updateLeague }}>
      {children}
    </LeagueContext.Provider>
  );
};
