import React, { useState, useEffect } from "react";
import TimeFormat from "../../services/TimeFormat";
import { useMediaQuery } from "@material-ui/core";
import "./GameCard.css";
import Switches from "../Switches.js";
import Modal from "react-modal";
import { addPrediction } from "../../Apis/predictions";
import displayToast from "../Alert/Alert";
import { useMutation } from "react-query";
import { useLeagueContext } from "../LeagueContext";
import Swal from "sweetalert2";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from "@mui/icons-material/EditOff";
import { teamNameMappings } from "./data";

const DesktopCard = ({ gameData }) => {
  const isMobile = useMediaQuery("(max-width:600px)");

  const [Pick_Ei, setPick_Ei] = useState(false);

  const labelStyles = {
    borderBottom: "2px solid #c1931b",
    width: "90%",
    margin: "3px",
    fontSize: "9px",
    textAlign: "center",
    color: "white",
    paddingBottom: "3px",
  };

  console.log(gameData)

  const [pick_visitor, setPickVisitor] = useState("");
  const [pick_home, setPickHome] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editedGameData, setEditedGameData] = useState({ ...gameData });
  const [Pick_Reg, setPick_Reg] = useState(false);
  const [Pick_ot, setPick_ot] = useState(false);
  const [Pick_so, setPick_so] = useState(false);
  const [Pick_num_ot, setPick_num_ot] = useState(0);
  const [Pick_num_ei, setPick_num_ei] = useState(0);
  const [Pick_num_so, setPick_num_so] = useState(0);
  const [inputChanged, setInputChanged] = useState(false);
  const [invalidFields, setInvalidFields] = useState([]);
  const [isLockedIn, setIsLockedIn] = useState(() => {
    const lockedStatus = localStorage.getItem(`isLockedIn_${gameData._id}`);
    return lockedStatus === "true";
  });
  const [buttonText, setButtonText] = useState(
    <>
      <LockOpenIcon /> LOCK IT IN
    </>
  );
  const [enterPickText, setEnterPickText] = useState(
    <>
      <EditIcon /> ENTER PICK
    </>
  );

  const [buttonStyle, setButtonStyle] = useState({
    backgroundColor: "initial",
    color: "black",
  });

  const [previousValues, setPreviousValues] = useState({
    pick_visitor: "",
    pick_home: "",
  });

  const cardId = gameData._id;

  const { selectedLeague } = useLeagueContext();

  const userId = localStorage.getItem("_id");

  const [gameEnding, setGameEnding] = useState(""); // State for gameEnding

  const isGameStarted = () => {
    try {
      // Assuming gameData.time is in format like "2:00 PM"
      const [timePart, meridiem] = gameData.time.split(' ');
      const [hours, minutes] = timePart.split(':');
      
      const gameDate = new Date(gameData.gamedate);
      let gameHours = parseInt(hours);
      
      // Convert to 24-hour format
      if (meridiem === 'PM' && gameHours !== 12) {
        gameHours += 12;
      } else if (meridiem === 'AM' && gameHours === 12) {
        gameHours = 0;
      }
      
      gameDate.setHours(gameHours, parseInt(minutes), 0);
      
      const currentTime = new Date();
      console.log('Game time:', gameDate);
      console.log('Current time:', currentTime);
      
      return currentTime >= gameDate;
    } catch (error) {
      console.error('Error parsing game time:', error);
      return false;
    }
  };

  // Disable buttons based on game start time
  const isGameDisabled = isGameStarted();

  const handleEnterPick = () => {
    if (isGameStarted()) {
      displayToast(
        "The game has started. You can no longer make predictions.",
        "error"
      );
      return;
    }
  
    if (isLockedIn) {
      displayToast(
        "This prediction is locked. You cannot modify it.",
        "error"
      );
      return;
    }
  
    const invalidFields = [];
    const visitorScore = parseInt(pick_visitor);
    const homeScore = parseInt(pick_home);
  
    // Check both fields together first
    if ((!pick_visitor || isNaN(visitorScore)) && (!pick_home || isNaN(homeScore))) {
      invalidFields.push("pick_visitor", "pick_home");
      displayToast("Please enter both team scores", "error");
      setInvalidFields(invalidFields);
      return;
    }
  
    // Then check individual fields
    if (!pick_visitor || isNaN(visitorScore)) {
      invalidFields.push("pick_visitor");
      displayToast("Please enter visitor team score", "error");
      setInvalidFields(invalidFields);
      return;
    }
  
    if (!pick_home || isNaN(homeScore)) {
      invalidFields.push("pick_home");
      displayToast("Please enter home team score", "error");
      setInvalidFields(invalidFields);
      return;
    }
  
    // Modified Zero Score validation based on league
    if (selectedLeague === "NBA") {
      // For NBA, no zeros allowed at all
      if (visitorScore === 0 || homeScore === 0) {
        Swal.fire({
          html: "Scores cannot be zero in NBA games.",
          icon: "error",
          confirmButtonText: "OK",
          background: "#212121",
          color: "white",
          showCancelButton: false,
        });
        return;
      }
    } else {
      // For NHL, NFL, MLB - one team can have zero but not both
      if (visitorScore === 0 && homeScore === 0) {
        Swal.fire({
          html: "Both teams cannot have zero scores.",
          icon: "error",
          confirmButtonText: "OK",
          background: "#212121",
          color: "white",
          showCancelButton: false,
        });
        return;
      }
    }
  
    setInvalidFields(invalidFields);
  
    let showAlert = false;
    let alertMessage = "";
    let showError = false;
    let errorMessage = "";
  
    switch (selectedLeague) {
      case "NHL":
      case "MLB":
        if (visitorScore > 9 || homeScore > 9) {
          showAlert = true;
          alertMessage = "The scores you entered are unusual.\nAre you sure you want to lock it in?";
        }
        if (visitorScore === homeScore) {
          showError = true;
          errorMessage = "Scores cannot be the same.";
        }
        break;
  
      case "NBA":
        if (visitorScore < 60 || visitorScore > 130 || homeScore < 60 || homeScore > 130) {
          showAlert = true;
          alertMessage = "The scores you entered are unusual.\nAre you sure?";
        }
        if (visitorScore === homeScore) {
          showError = true;
          errorMessage = "Scores cannot be the same.";
        }
        break;
  
      case "NFL":
        if (visitorScore > 35 || homeScore > 35) {
          showAlert = true;
          alertMessage = "The scores you entered are unusual.\nAre you sure?";
        }
        if (visitorScore === homeScore) {
          showAlert = true;
          alertMessage = "Scores are the same. Are you sure?";
        }
        break;
    }
  
    if (showError) {
      displayToast(errorMessage, "error");
    } else if (showAlert) {
      Swal.fire({
        html: alertMessage.split("\n").join("<br>"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        background: "#212121",
        color: "white",
        focusCancel: true,
        reverseButtons: true,
        buttonsStyling: true,
        customClass: {
          cancelButton: 'swal2-default-button'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          // Save to localStorage
          const dataToSave = {
            gameData: gameData._id,
            pick_visitor,
            pick_home,
            gameEnding,
            userId,
            Pick_num_ot,
            Pick_num_ei,
            Pick_num_so,
            Pick_so,
            Pick_ot,
            Pick_Reg,
          };
          localStorage.setItem(gameData._id, JSON.stringify(dataToSave));
          displayToast(
            "Prediction saved. Editable until game starts.",
            "success"
          );
        }
      });
    } else {
      // Save to localStorage without showing alert
      const dataToSave = {
        gameData: gameData._id,
        pick_visitor,
        pick_home,
        gameEnding,
        userId,
        Pick_num_ot,
        Pick_num_ei,
        Pick_num_so,
        Pick_so,
        Pick_ot,
        Pick_Reg,
      };
      localStorage.setItem(gameData._id, JSON.stringify(dataToSave));
      displayToast("Prediction saved. Editable until game starts.", "success");
    }
  };
  

  // Lock in prediction and prevent further edits
  const lockInPrediction = () => {
    const timestamp = new Date().toISOString();

    const dataToSave = {
      gameData: gameData._id,
      pick_visitor,
      pick_home,
      gameEnding,
      userId,
      Pick_num_ot,
      Pick_num_ei,
      Pick_num_so,
      Pick_so,
      Pick_ot,
      Pick_Reg,
      league: selectedLeague,
    };

    mutate(dataToSave); // Send to backend

    // Set the lock-in state to true so the warning doesn't appear
    setIsLockedIn(true);
    localStorage.setItem(`isLockedIn_${gameData._id}`, "true"); // Save the lock status
    // displayToast("Prediction locked.", "success");

    // Remove the local storage entry
    localStorage.removeItem(gameData._id);
  };

  // Warn user if they try to close the tab without locking in
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!isLockedIn) {
        // Set a custom warning message
        const message =
          "You haven't locked in your prediction yet. Do you want to leave without locking it in?";
        event.returnValue = message; // For most modern browsers
        return message; // For older browsers
      }
    };

    // Attach event listener if the prediction is not locked
    if (!isLockedIn) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    // Cleanup the event listener
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isLockedIn]);

  // Call this function to send the locked-in prediction to the backend when game starts
  const sendPredictionToBackend = () => {
    if (isGameStarted()) {
      const dataFromLocalStorage = localStorage.getItem(gameData._id);
      if (dataFromLocalStorage) {
        const parsedData = JSON.parse(dataFromLocalStorage);
        mutate(parsedData);
        localStorage.removeItem(gameData._id);
        setIsLockedIn(true); // Lock the prediction
        // displayToast("Prediction locked.", "success");
      }
    }
  };
  // Update button text based on lock status
  useEffect(() => {
    if (isLockedIn) {
      setButtonText(
        <>
          LOCKED <LockIcon />
        </>
      );
      setButtonStyle({ backgroundColor: "initial", color: "red" });
    } else {
      setButtonText(
        <>
          <LockOpenIcon /> LOCK IT IN
        </>
      );
      setButtonStyle({ backgroundColor: "initial", color: "black" });
    }
  }, [isLockedIn]);

  // Check if game has started and auto-lock the prediction
  useEffect(() => {
    if (isGameStarted()) {
      sendPredictionToBackend();
    }
  }, [gameData]);

  const { mutate, isLoading, isError, data, error, reset } = useMutation(
    (data) => addPrediction(data),
    {
      onSuccess: (data) => {
        // displayToast("Prediction added successfully", "success");
      },
      onError: (error) => {
        displayToast("Error while adding the prediction", "error");
      },
    }
  );

  useEffect(() => {
    if (isLockedIn) {
      // If the "LOCK IT IN" button is clicked, show EditOffIcon
      setEnterPickText(
        <>
          {/* <EditOffIcon /> */}
          GAME PLAYED
        </>
      );
    } else {
      // If the "LOCK IT IN" button is not clicked, show EditIcon
      setEnterPickText(
        <>
          <EditIcon /> ENTER
          <br />
          PICK
        </>
      );
    }
  }, [isLockedIn]);

  useEffect(() => {
    const date = new Date(gameData?.gamedate);
    const options = { month: "short", day: "numeric", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
  }, [gameData]);

  useEffect(() => {
    const storedVisitor = localStorage.getItem(`pick_visitor_${cardId}`);
    const storedHome = localStorage.getItem(`pick_home_${cardId}`);

    if (storedVisitor) {
      setPickVisitor(storedVisitor);
    }
    if (storedHome) {
      setPickHome(storedHome);
    }
  }, [cardId]);

  // Handle input change for visitor (allow only numbers)
  const handleInputChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      // Allows only numeric input
      if (value !== pick_visitor) {
        setInputChanged(true);
      }
      setPickVisitor(value);
      localStorage.setItem(`pick_visitor_${cardId}`, value); 

      // Set expiration timestamp
      const expirationTime = new Date();
      expirationTime.setHours(2, 0, 0, 0); // 2 AM
      localStorage.setItem(
        `expiration_${cardId}`,
        expirationTime.toISOString()
      );
    }
  };

  // Handle input change for home (allow only numbers)
  const handleHomeChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      // Allows only numeric input
      if (value !== pick_home) {
        setInputChanged(true);
      }
      setPickHome(value);
      localStorage.setItem(`pick_home_${cardId}`, value); // Save to localStorage with unique key

      // Set expiration timestamp
      const expirationTime = new Date();
      expirationTime.setHours(2, 0, 0, 0); // 2 AM
      localStorage.setItem(
        `expiration_${cardId}`,
        expirationTime.toISOString()
      );
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (inputChanged) {
        const message =
          "You have unsaved changes, are you sure you want to leave?";
        e.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [inputChanged]);

  const handleSaveEdit = () => {
    setIsModalOpen(false);
    setInputChanged(false);
    setPreviousValues({ pick_visitor, pick_home });
    console.log("Saved data:", editedGameData);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const renderSwitches = (team) => (
    <Switches
      league={gameData?.league}
      season={gameData?.seasonflag}
      setPick_num_ot={setPick_num_ot}
      setPick_num_ei={setPick_num_ei}
      setPick_num_so={setPick_num_so}
      setPick_so={setPick_so}
      setPick_ot={setPick_ot}
      setPick_Reg={setPick_Reg}
      setPick_Ei={setPick_Ei}
      uniqueId={gameData._id}
      glowing={invalidFields.includes("pick_switch")}
      setGameEnding={setGameEnding} // Pass the function to update gameEnding
    />
  );

  const handleLockIn = () => {
    if (isGameStarted()) {
      displayToast(
        "The game has started. You can no longer make predictions.",
        "error"
      );
      return;
    }
  
    if (isLockedIn) {
      displayToast(
        "This prediction is locked. You cannot modify it.",
        "error"
      );
      return;
    }
  
    const invalidFields = [];
    const visitorScore = parseInt(pick_visitor);
    const homeScore = parseInt(pick_home);
  
    // Check both fields together first
    if ((!pick_visitor || isNaN(visitorScore)) && (!pick_home || isNaN(homeScore))) {
      invalidFields.push("pick_visitor", "pick_home");
      displayToast("Please enter both team scores", "error");
      setInvalidFields(invalidFields);
      return;
    }
  
    // Then check individual fields
    if (!pick_visitor || isNaN(visitorScore)) {
      invalidFields.push("pick_visitor");
      displayToast("Please Enter Visitor Team Score", "error");
      setInvalidFields(invalidFields);
      return;
    }
  
    if (!pick_home || isNaN(homeScore)) {
      invalidFields.push("pick_home");
      displayToast("Please Enter Home Team Score", "error");
      setInvalidFields(invalidFields);
      return;
    }
  
    // Modified Zero Score validation based on league
    if (selectedLeague === "NBA") {
      // For NBA, no zeros allowed at all
      if (visitorScore === 0 || homeScore === 0) {
        Swal.fire({
          html: "Scores cannot be zero in NBA games.",
          icon: "error",
          confirmButtonText: "OK",
          background: "#212121",
          color: "white",
          showCancelButton: false,
        });
        return;
      }
    } else {
      // For NHL, NFL, MLB - one team can have zero but not both
      if (visitorScore === 0 && homeScore === 0) {
        Swal.fire({
          html: "Both teams cannot have zero scores.",
          icon: "error",
          confirmButtonText: "OK",
          background: "#212121",
          color: "white",
          showCancelButton: false,
        });
        return;
      }
    }
  
    setInvalidFields(invalidFields);
  
    let showAlert = false;
    let alertMessage = "";
    let showError = false;
    let errorMessage = "";
  
    switch (selectedLeague) {
      case "NHL":
      case "MLB":
        if (visitorScore > 10 || homeScore > 10) {
          showAlert = true;
          alertMessage = "The scores you entered are unusual.\nAre you sure you want to lock it in?";
        }
        if (visitorScore === homeScore) {
          showError = true;
          errorMessage = "Scores cannot be the same.";
        }
        break;
  
      case "NBA":
        if (visitorScore < 60 || visitorScore > 150 || homeScore < 60 || homeScore > 150) {
          showAlert = true;
          alertMessage = "The scores you entered are unusual.\nAre you sure?";
        }
        if (visitorScore === homeScore) {
          showError = true;
          errorMessage = "Scores cannot be the same.";
        }
        break;
  
      case "NFL":
        if (visitorScore > 35 || homeScore > 35) {
          showAlert = true;
          alertMessage = "The scores you entered are unusual.\nAre you sure?";
        }
        if (visitorScore === homeScore) {
          showAlert = true;
          alertMessage = "Scores are the same. Are you sure?";
        }
        break;

      default:
        break; // Add default case to handle any unmatched leagues
    }
  
    if (showError) {
      displayToast(errorMessage, "error");
    } else if (showAlert) {
      Swal.fire({
        html: alertMessage.split("\n").join("<br>"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        background: "#212121",
        color: "white",
        focusCancel: true,
        reverseButtons: true,
        buttonsStyling: true,
        customClass: {
          cancelButton: 'swal2-default-button'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          lockInPrediction();
        }
      });
    } else {
      lockInPrediction();
    }
  };
  

  useEffect(() => {
    const lockedStatus = localStorage.getItem(`isLockedIn_${gameData._id}`);
    if (lockedStatus === "true") {
      setIsLockedIn(true);
      setButtonText(
        <>
          LOCKED <LockIcon />
        </>
      );
      setButtonStyle({ backgroundColor: "initial", color: "red" });
      setEnterPickText(
        <>
          GAME PLAYED
        </>
      );
    }

    if (isGameStarted()) {
      setIsLockedIn(true);
      localStorage.setItem(`isLockedIn_${gameData._id}`, "true");
      sendPredictionToBackend();
    }
  }, [gameData._id]);

  // Update the useEffect to handle different game states
  useEffect(() => {
    const checkGameStatus = () => {
      const lockedStatus = localStorage.getItem(`isLockedIn_${gameData._id}`);
      
      if (isGameStarted()) {
        // Game has started - show GAME STARTED in red
        console.log('Game has started!');
        setIsLockedIn(true);
        setButtonText(<><LockIcon /> GAME STARTED</>);
        setEnterPickText(<>GAME STARTED</>);
        setButtonStyle({ backgroundColor: "initial", color: "red" }); // Changed to red text
        
        // Disable all inputs in this card
        const cardElement = document.querySelector(`#game-card-${gameData._id}`);
        if (cardElement) {
          const inputs = cardElement.querySelectorAll('input');
          inputs.forEach(input => {
            input.disabled = true;
          });
        }
        
        // Send prediction to backend if not already locked
        if (!lockedStatus) {
          sendPredictionToBackend();
          localStorage.setItem(`isLockedIn_${gameData._id}`, "true");
        }
      } else if (lockedStatus === "true") {
        // Game hasn't started but prediction is locked - show LOCKED
        setIsLockedIn(true);
        setButtonText(<><LockIcon /> LOCKED</>);
        setEnterPickText(<>GAME PLAYED</>);
        setButtonStyle({ backgroundColor: "initial", color: "red" });
      } else {
        // Normal state - not started, not locked
        setButtonText(<><LockOpenIcon /> LOCK IT IN</>);
        setEnterPickText(
          <>
            <EditIcon /> ENTER
            <br />
            PICK
          </>
        );
        setButtonStyle({ backgroundColor: "initial", color: "black" });
      }
    };

    // Check immediately
    checkGameStatus();

    // Check every second (1000ms) instead of every minute (60000ms)
    const interval = setInterval(checkGameStatus, 1000);

    return () => clearInterval(interval);
  }, [gameData._id, gameData.time, gameData.gamedate]);

  // Update button styling for GAME STARTED state
  const getButtonStyle = () => {
    if (isGameStarted()) {
      return {
        backgroundColor: "initial",
        color: "red", // Changed to red text for game started
        fontSize: isMobile ? "8px" : "11px",
      };
    }
    if (isLockedIn) {
      return {
        backgroundColor: "initial",
        color: "red",
        fontSize: isMobile ? "8px" : "9px",
      };
    }
    return buttonStyle;
  };

  // Add this function to check score difference
  const getScoreDifference = () => {
    const visitorScore = parseInt(pick_visitor) || 0;
    const homeScore = parseInt(pick_home) || 0;
    return Math.abs(visitorScore - homeScore);
  };

  // Update the handlePickChange function
  const handlePickChange = (field, value) => {
    setInputChanged(true);
    if (field === "pick_visitor") {
      setPickVisitor(value);
      // Check score difference for NHL games
      if (gameData?.league === "NHL" && Math.abs(parseInt(value) - parseInt(pick_home)) > 1) {
        setPick_ot(false);
        setPick_num_ot("");
        setPick_so(false);
        setPick_num_so("");
      }
    } else if (field === "pick_home") {
      setPickHome(value);
      // Check score difference for NHL games
      if (gameData?.league === "NHL" && Math.abs(parseInt(pick_visitor) - parseInt(value)) > 1) {
        setPick_ot(false);
        setPick_num_ot("");
        setPick_so(false);
        setPick_num_so("");
      }
    }
  };

  // Update button and input disabled states
  const isDisabled = isGameStarted() || isLockedIn;

  return (
    <>
      <div id={`game-card-${gameData._id}`} className="game-card grid col-span-2 xl:col-span-1">
        <div
          className="flex justify-between"
          // style={{ border: "2px solid red" }}
        >
          <div style={{ paddingRight: "3.3%" }} className="flex flex-col">
            {/* <div
              className="game-time test-size"
              style={{
                display: "flex",
                WebkitTextStroke: "0.3px black",
                textStroke: "0.3px black",
                textShadow: "3px 7px 7px rgba(255, 0, 0, 0.25)",
                fontSize: isMobile ? "9px" : "12px",
              }}
            >
              {TimeFormat(gameData?.time)}
            </div> */}
            <div
              style={{
                fontSize: "12px",
                marginLeft: "-10px",
                // WebkitTextStroke: "0.3px black", // Subtle black stroke
                // color: "#fff", // White text color
                // textShadow: "1px 1px 2px rgba(255, 255, 255, 0.8)", // White shadow
                background:
                  "linear-gradient(180deg, #be8200 0%, #fef098 47.4%, #efd261 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                backgroundClip: "text",
                textFillColor: "transparent",
              }}
              className="game-date"
            >
              {gameData.gamedate}
            </div>
            {/* <div className="game-date">{gameData.gamedate}</div> &nbsp; */}
            <input
              type="text"
              id={`pick_visitor_${cardId}`}
              className={`score-input card-input mb-2  ${
                invalidFields.includes("pick_visitor") ? "glowing-border" : ""
              }`}
              value={pick_visitor}
              onChange={handleInputChange}
              disabled={isDisabled}
            />
          </div>

          <div className="flex flex-col">
            <div
              className="game-time test-size font-inter mb-3"
              style={{
                // WebkitTextStroke: "0.3px black",
                fontSize: isMobile ? "10px" : "10px",
              }}
            >
              <div
                style={{
                  fontSize: "12px",
                  marginLeft: "15px",
                  WebkitTextStroke: "0.3px black", // Subtle black stroke
                  color: "#fff", // White text color
                  textShadow: "1px 1px 2px rgba(255, 255, 255, 0.8)", // White shadow
                }}
                className="game-date"
              >
                {/* {gameData.gamedate} */}
              </div>
              {/* Team */}
            </div>
            <div
              style={{
                width: "6rem",
                height: "3rem",
                display: "flex",
                marginRight: "20px",
                flexDirection: "column",
                alignItems: "center", // Align items horizontally (center)
                justifyContent: "center", // Align items vertically (center)
                textAlign: "center", // Align text within the label
              }}
              className="box px-7 "
            >
              <div
                className="team-names"
                style={{
                  fontSize: "12px",
                  background:
                    "linear-gradient(180deg, #be8200 0%, #fef098 47.4%, #efd261 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  backgroundClip: "text",
                  textFillColor: "transparent",
                }}
              >
                {teamNameMappings[gameData?.visitor] || gameData?.visitor}
              </div>
            </div>
          </div>

          <div className="flex flex-col">
            <div
              className="game-time test-size font-inter mb-3"
              style={{
                // WebkitTextStroke: "0.3px black",
                fontSize: isMobile ? "10px" : "13px",
                margin: "auto",
                // color: "#c1931b",
                // textShadow: "0px 0px 5px rgba(0, 255, 0, 1)",
                background:
                  "linear-gradient(180deg, #be8200 0%, #fef098 47.4%, #efd261 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                backgroundClip: "text",
                textFillColor: "transparent",
              }}
            >
              Money Line
            </div>

            <div
              className="box px-7 h-12"
              style={{
                width: "6rem",
                height: "3rem",
              }}
            >
              <label style={labelStyles}>{gameData?.["v-ml"]}</label>
              <label
                style={{
                  fontSize: "8.5px",
                  marginTop: "3px",
                }}
              >
                {gameData?.["v-ml-points"]} PTS
              </label>
            </div>
          </div>

          <div className="flex flex-col">
            <div
              className="game-time test-size font-inter mb-3"
              style={{
                // WebkitTextStroke: "0.3px black",
                fontSize: isMobile ? "10px" : "13px",
                // color: "#c1931b",
                // textShadow: "0px 0px 5px rgba(0, 255, 0, 1)",
                margin: "auto",
                background:
                  "linear-gradient(180deg, #be8200 0%, #fef098 47.4%, #efd261 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                backgroundClip: "text",
                textFillColor: "transparent",
              }}
            >
              Spread
            </div>
            <div
              className="box px-7 h-12"
              style={{
                width: "6rem",
                height: "3rem",
              }}
            >
              <label style={labelStyles}>{gameData?.["v-sprd"]}</label>
              <label
                className="text-white"
                style={{
                  fontSize: "8.5px",
                  marginTop: "3px",
                }}
              >
                {gameData?.["v-sprd-points"]} PTS
              </label>
            </div>
          </div>
          <div className="flex flex-col">
            <div
              className="game-time test-size font-inter mb-3"
              style={{
                // WebkitTextStroke: "0.3px black",
                fontSize: isMobile ? "10px" : "13px",
                // color: "#c1931b",
                // textShadow: "0px 0px 5px rgba(0, 255, 0, 1)",
                margin: "auto",
                background:
                  "linear-gradient(180deg, #be8200 0%, #fef098 47.4%, #efd261 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                backgroundClip: "text",
                textFillColor: "transparent",
              }}
            >
              Over/Under
            </div>
            <div
              className="box px-7 h-12"
              style={{
                width: "6rem",
                height: "3rem",
              }}
            >
              <label style={labelStyles}>{gameData?.["v-ou"]}</label>
              <label
                style={{
                  fontSize: "8.5px",
                  marginTop: "3px",
                }}
              >
                {gameData?.["v-ou-points"]} PTS
              </label>
            </div>
          </div>
        </div>

        <div className="flex justify-between gap-1">
          <div
            className="line"
            style={{
              width: "8%",
              margin: "20px 0px",
              background: "white",
              marginLeft: "8px",
            }}
          ></div>
          <div
            className="line"
            style={{
              width: "19%",
              margin: "20px -14px -20px 0px",
              background: "white",
              marginLeft: "8px",
            }}
          ></div>
          <div
            className="line"
            style={{
              width: "59.6%",
              background: "white",
              margin: "20px 0px",
            }}
          ></div>
        </div>

        <div className="flex justify-between">
          <div className="flex flex-col" style={{ paddingRight: "4.5%" }}>
            <input
              type="text"
              id={`pick_home_${cardId}`}
              className={`score-input card-input mb-2 mt-auto ${
                invalidFields.includes("pick_home") ? "glowing-border" : ""
              }`}
              value={pick_home}
              onChange={handleHomeChange}
              disabled={isDisabled} // Disable button when locked
            />
          </div>

          <div className="flex flex-col justify-start">
            <div
              className="box px-7 h-12"
              style={{
                width: "6rem",
                height: "3rem",
                display: "flex",
                marginRight: "20px",
                flexDirection: "column",
                alignItems: "center", // Align items horizontally (center)
                justifyContent: "center", // Align items vertically (center)
                textAlign: "center", // Align text within the label
              }}
            >
              <div
                className="upside-down"
                style={{
                  fontSize: "12px",
                  background:
                    "linear-gradient(180deg, #be8200 0%, #fef098 47.4%, #efd261 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  backgroundClip: "text",
                  textFillColor: "transparent",
                }}
              >
                {teamNameMappings[gameData?.home] || gameData?.home}
              </div>
            </div>
          </div>

          <div
            className="flex flex-col justify-start"
            style={{
              // WebkitTextStroke: "0.3px black",
              fontSize: isMobile ? "10px" : "12px",
            }}
          >
            <div
              className="box px-7 h-12"
              style={{
                width: "6rem",
                height: "3rem",
              }}
            >
              <label style={labelStyles}>{gameData?.["h-ml"]}</label>
              <label
                style={{
                  fontSize: "8.5px",
                  marginTop: "3px",
                }}
              >
                {gameData?.["h-ml-points"]} PTS
              </label>
            </div>
          </div>

          <div
            className="flex flex-col justify-start"
            style={{
              // WebkitTextStroke: "0.3px black",
              fontSize: isMobile ? "10px" : "11px",
            }}
          >
            <div
              className="box px-7 h-12"
              style={{
                width: "6rem",
                height: "3rem",
              }}
            >
              <label style={labelStyles}>{gameData?.["h-sprd"]}</label>
              <label style={{ fontSize: "8.5px", marginTop: "3px" }}>
                {gameData?.["h-sprd-points"]} PTS
              </label>
            </div>
          </div>
          <div
            className="flex flex-col"
            style={{
              // WebkitTextStroke: "0.3px black",
              fontSize: isMobile ? "10px" : "12px",
            }}
          >
            <div
              className="box px-7 h-12"
              style={{
                width: "6rem",
                height: "3rem",
              }}
            >
              <label style={labelStyles}>{gameData?.["h-ou"]}</label>
              <label
                style={{
                  fontSize: "8.5px",
                  marginTop: "3px",
                }}
              >
                {gameData?.["h-ou-points"]} PTS
              </label>
            </div>
          </div>
        </div>
        <div className="flex justify-between gap-1">
          <div
            className="line"
            style={{
              width: "8%",
              // height : '20px',
              background: "white",
              margin: "20px 0px 20px  0px",
              marginLeft: "8px",
            }}
          ></div>
          <div
            className="line"
            style={{
              width: "82.7%",
              background: "white",
              margin: "20px 0px 20px  0px",
            }}
          ></div>
        </div>

        <div
          className="flex justify-between items-center"
          style={{ position: "relative", zIndex: 1 }}
        >
          <div
            className="game-time test-size"
            style={{
              marginTop: "0px",
              marginLeft: "0px",
              WebkitTextStroke: "0.3px black",
              textStroke: "0.3px black",
              textShadow: "3px 7px 7px rgba(255, 0, 0, 0.25)",
              fontSize: "12px",
              position: "absolute",
              left: "0",
              zIndex: 2,
              // backgroundColor: "#1B1C21", // Match your card background color
              padding: "0 5px",
              minWidth: "fit-content",
              isolation: "isolate",
            }}
          >
            {TimeFormat(gameData?.time)}
          </div>

          {/* Add a spacer to prevent content overlap */}
          <div style={{ width: "40px" }}></div>

          <Switches
            league={gameData?.league}
            season={gameData?.seasonflag}
            setPick_num_ot={setPick_num_ot}
            setPick_num_ei={setPick_num_ei}
            setPick_num_so={setPick_num_so}
            setPick_so={setPick_so}
            setPick_ot={setPick_ot}
            setPick_Reg={setPick_Reg}
            setPick_Ei={setPick_Ei}
            uniqueId={gameData._id}
            glowing={invalidFields.includes("pick_switch")}
            setGameEnding={setGameEnding}
          />

          <div
            className="button-pick"
            style={{
              display: "flex",
              columnGap: "1vh",
              // border: "2px solid blue",
            }}
          >
            <button
              className="card-btn-enter mt-1"
              style={{
                fontSize: isMobile ? "10px" : "11px",
                color: "white",
                width: "90px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "6px", // Space between icon and text
              }}
              onClick={handleEnterPick}
              disabled={isDisabled}
            >
              {enterPickText}
            </button>{" "}
            <button
              className="card-btn mt-1"
              style={{
                width: "90px",
                fontSize: isMobile ? "10px" : "11px",
                ...getButtonStyle(), // Apply the button style dynamically based on the state
                display: "flex",
                alignItems: "center", // Aligns text and icon vertically
                justifyContent: "center",
                gap: "6px", // Space between icon and text
              }}
              onClick={handleLockIn}
              disabled={isDisabled}
            >
              {buttonText}
            </button>{" "}
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        contentLabel="Edit Game Data"
      >
        <h2>Edit Game Data</h2>
        <form>
          <input
            type="text"
            value={editedGameData?.visitor}
            onChange={(e) =>
              setEditedGameData({ ...editedGameData, visitor: e.target.value })
            }
          />
          <button onClick={handleSaveEdit}>Save</button>
          <button onClick={handleModalClose}>Cancel</button>
        </form>
      </Modal>
    </>
  );
};

export default DesktopCard;
