import React, { useState, useEffect } from "react";
import { addGame, checkGameExists, getSubmittedGames } from "../../Apis/games";
import { useMutation, useQuery } from "react-query";
import { useDispatch } from "react-redux";
import displayToast from "../../components/Alert/Alert";
import { getTeasmByLeage } from "../../Apis/Teams";
import { getOdds } from "../../Apis/odds";
import Button from "@mui/material/Button";
import { headerOptions } from "./data"; // Import headerOptions

const GameForm = () => {
  const [odds, setOdds] = useState(null);
  const dispatch = useDispatch();

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [submittedGames, setSubmittedGames] = useState([]);
  const [formData, setFormData] = useState({
    game: "",
    fromDate: "",
    gamedate: "",
    toDate: "",
    league: "",
    time: "",
    visitorTeam: "",
    vML: "",
    vSprd: "",
    vSprdOdds: "",
    vOU: "",
    vOUOdds: "",
    homeTeam: "",
    hML: "",
    hSprd: "",
    hSprdOdds: "",
    hOU: "",
    hOUOdds: "",
    sport: "",
  });

  const { mutate, reset } = useMutation((data) => addGame(data), {
    onError: (err) => {
      console.error("Error adding game:", err);
      displayToast("An error occurred while adding the game.", "error");
    },
    onSuccess: (rec) => {
      displayToast("Game added successfully.", "success");
      setSubmittedGames([...submittedGames, formData.game]);
      reset();
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const [gameData, setGameData] = useState(null);
  const [loading, setLoading] = useState(false);

  // Initial load of submitted games from database
  useEffect(() => {
    const fetchSubmittedGames = async () => {
      try {
        const games = await getSubmittedGames();
        setSubmittedGames(games);
      } catch (error) {
        console.error("Error fetching submitted games:", error);
        displayToast("Error loading game history", "error");
      }
    };

    fetchSubmittedGames();
  }, []); // Run once on component mount

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const gameData = createGameData();
      
      // Check if games already exist in database
      const existingGames = await checkGameExists(gameData);
      
      if (existingGames.exists) {
        displayToast("These games are already in the database!", "error");
        return;
      }

      // Submit new games
      await mutate(gameData);
      
      // Refresh the submitted games list
      const updatedGames = await getSubmittedGames();
      setSubmittedGames(updatedGames);
      
      displayToast("Games added successfully!", "success");
      
    } catch (error) {
      console.error("Error:", error);
      displayToast("Error submitting games", "error");
    }
  };

  const handleOdds = (e) => {
    e.preventDefault();
    console.log("Form data:", formData);

    getOdds(formData.game)
      .then((oddsData) => {
        console.log("Odds data:", oddsData);

        // Filter out incomplete game data
        const validOdds = oddsData.data.filter(
          (odd) =>
            odd.away_team &&
            odd.home_team &&
            odd.bookmakers &&
            odd.bookmakers?.length > 0
          // &&
          // odd.bookmakers[0]?.markets &&
          // odd.bookmakers[0]?.markets?.length >= 3
        );

        if (validOdds.length === 0) {
          displayToast("No valid games available.", "info");
        } else {
          setOdds(validOdds);
        }
      })
      .catch((error) => {
        console.error("Error fetching odds:", error);
        displayToast("An error occurred while fetching odds.", "error");
      });
  };

  const createGameData = () => {
    return odds.map((odd, index) => {
      const [gamedate, time] = odd.commence_time.split("T");

      // Here I'm adding an 'id' based on the index, but you can modify this as needed.
      return {
        id: odd.id || `game-${index}`, // Use odd.id if available, otherwise generate one
        game: formData.game,
        league: formData.league,
        date: (() => {
          const date = new Date(odd.commence_time);
          const options = {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            timeZone: "America/Halifax",
          };
          return date.toLocaleDateString("en-US", options).replace(/\//g, "-");
        })(),
        time: (() => {
          const date = new Date(odd.commence_time);
          const options = {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
            timeZone: "America/Halifax",
          };
          return date.toLocaleTimeString("en-US", options);
        })(),
        visitorTeam: odd.away_team,
        vML:
          odd.away_team == odd.bookmakers[0]?.markets[0]?.outcomes?.[0].name
            ? Number.isInteger(
                odd.bookmakers[0]?.markets[0]?.outcomes?.[0]?.price
              )
              ? odd.bookmakers[0]?.markets[0]?.outcomes?.[0]?.price / 100
              : odd.bookmakers[0]?.markets[0]?.outcomes?.[0]?.price
            : Number.isInteger(
                odd.bookmakers[0]?.markets[0]?.outcomes?.[1]?.price
              )
            ? odd.bookmakers[0]?.markets[0]?.outcomes?.[1]?.price / 100
            : odd.bookmakers[0]?.markets[0]?.outcomes?.[1]?.price,

        vSprd:
          odd.away_team == odd.bookmakers[0]?.markets[1]?.outcomes?.[0]?.name
            ? odd.bookmakers[0]?.markets[1]?.outcomes?.[0]?.point
            : odd.bookmakers[0]?.markets[1]?.outcomes?.[1]?.point,

        vSprdOdds:
          odd.away_team == odd.bookmakers[0]?.markets[1]?.outcomes?.[0]?.name
            ? Number.isInteger(
                odd.bookmakers[0]?.markets[1]?.outcomes?.[0]?.price
              )
              ? odd.bookmakers[0]?.markets[1]?.outcomes?.[0]?.price / 100
              : odd.bookmakers[0]?.markets[1]?.outcomes?.[0]?.price
            : Number.isInteger(
                odd.bookmakers[0]?.markets[1]?.outcomes?.[1]?.price
              )
            ? odd.bookmakers[0]?.markets[1]?.outcomes?.[1]?.price / 100
            : odd.bookmakers[0]?.markets[1]?.outcomes?.[1]?.price,
        vOU: odd.bookmakers[0]?.markets[2]?.outcomes?.[0]
          ? Number.isInteger(
              odd.bookmakers[0]?.markets[2]?.outcomes?.[0]?.point
            )
            ? odd.bookmakers[0]?.markets[2]?.outcomes?.[0]?.point + 0.5
            : odd.bookmakers[0]?.markets[2]?.outcomes?.[0]?.point
          : undefined,
        vOUOdds: odd.bookmakers[0]?.markets[2]?.outcomes?.[0]
          ? Number.isInteger(
              odd.bookmakers[0]?.markets[2]?.outcomes?.[0]?.price
            )
            ? odd.bookmakers[0]?.markets[2]?.outcomes?.[0]?.price / 100
            : odd.bookmakers[0]?.markets[2]?.outcomes?.[0]?.price
          : undefined,
        homeTeam: odd.home_team,
        hML:
          odd.home_team == odd.bookmakers[0]?.markets[0]?.outcomes?.[1]?.name
            ? Number.isInteger(
                odd.bookmakers[0]?.markets[0]?.outcomes?.[1]?.price
              )
              ? odd.bookmakers[0]?.markets[0]?.outcomes?.[1]?.price / 100
              : odd.bookmakers[0]?.markets[0]?.outcomes?.[1]?.price
            : Number.isInteger(
                odd.bookmakers[0]?.markets[0]?.outcomes?.[0]?.price
              )
            ? odd.bookmakers[0]?.markets[0]?.outcomes?.[0]?.price / 100
            : odd.bookmakers[0]?.markets[0]?.outcomes?.[0]?.price,

        hSprd:
          odds.home_team == odd.bookmakers[0]?.markets[1]?.outcomes?.[1]?.name
            ? odd.bookmakers[0]?.markets[1]?.outcomes?.[1]?.point
            : odd.bookmakers[0]?.markets[1]?.outcomes?.[0]?.point,
        hSprdOdds:
          odd.home_team == odd.bookmakers[0]?.markets[1]?.outcomes?.[1]?.name
            ? Number.isInteger(
                odd.bookmakers[0]?.markets[1]?.outcomes?.[1]?.price
              )
              ? odd.bookmakers[0]?.markets[1]?.outcomes?.[1]?.price / 100
              : odd.bookmakers[0]?.markets[1]?.outcomes?.[1]?.price
            : Number.isInteger(
                odd.bookmakers[0]?.markets[1]?.outcomes?.[0]?.price
              )
            ? odd.bookmakers[0]?.markets[1]?.outcomes?.[0]?.price / 100
            : odd.bookmakers[0]?.markets[1]?.outcomes?.[0]?.price,
        hOU: odd.bookmakers[0]?.markets[2]?.outcomes?.[1]
          ? Number.isInteger(
              odd.bookmakers[0]?.markets[2]?.outcomes?.[1]?.point
            )
            ? odd.bookmakers[0]?.markets[2]?.outcomes?.[1]?.point + 0.5
            : odd.bookmakers[0]?.markets[2]?.outcomes?.[1]?.point
          : undefined,
        hOUOdds: Number.isInteger(
          odd.bookmakers[0]?.markets[2]?.outcomes?.[1]?.price
        )
          ? odd.bookmakers[0]?.markets[2]?.outcomes?.[1]?.price / 100
          : odd.bookmakers[0]?.markets[2]?.outcomes?.[1]?.price,
        sport: formData.sport,
      };
    });
  };

  return (
    <div className="p-4">
      <h2 className="text-white text-xl mb-4 align-items-center">
        Enter Game Details
      </h2>
      <form className="justify-center items-center h-screen text-yellow-500">
        <div className="flex flex-wrap -mx-2 ">
          <div className="mb-4 px-2">
            <label htmlFor="game">Game</label>
            <select
              id="game"
              name="game"
              value={formData.game}
              onChange={(e) => {
                const selectedOption = e.target.options[e.target.selectedIndex];
                setFormData({
                  ...formData,
                  game: e.target.value,
                  league: selectedOption.getAttribute("name"),
                });
              }}
              className="bg-gray-800 text-white p-2 rounded w-full"
            >
              <option value="">Select a game</option>
              <optgroup label="Hockey">
                <option name="NHL" value="icehockey_nhl">
                  NHL
                </option>
              </optgroup>
              <optgroup label="FOOTBALL">
                {/* <option name="CFL" value="americanfootball_cfl">
                  CFL
                </option>
                <option name="NCAAF" value="americanfootball_ncaaf">
                  NCAAF
                </option> */}
                <option name="NFL" value="americanfootball_nfl">
                  NFL
                </option>
                {/* <option name="UFL" value="americanfootball_ufl">
                  UFL
                </option> */}
              </optgroup>
              <optgroup label="BASEBALL">
                {/* <option name="NCCA" value="baseball_ncaa">
                  NCCA
                </option> */}
                <option name="MLB" value="baseball_mlb">
                  MLB
                </option>
              </optgroup>
              <optgroup label="BASKETBALL">
                <option name="NBA" value="basketball_nba">
                  NBA
                </option>
                {/* <option name="WNBA" value="basketball_wnba">
                  WNBA
                </option>
                <option name="NCAAB" value="basketball_ncaab">
                  NCAAB
                </option> */}
              </optgroup>
            </select>
          </div>
        </div>
        <Button
          type="submit"
          variant="contained"
          style={{
            backgroundColor: "#FFD700",
            color: "rgba(0, 0, 0, 1)",
            marginLeft: "10px",
          }}
          onClick={handleOdds}
        >
          Submit ✔
        </Button>
      </form>
      <br />

      <form
        onSubmit={handleSubmit}
        // className="justify-center items-center h-screen text-yellow-500"
      >
        {odds && odds.length > 0 ? (
          odds.map((odd) => (
            <div className="game-card" key={odd.id}>
              {/* Only render if we have complete data */}
              {odd.away_team &&
                odd.home_team &&
                odd.bookmakers &&
                odd.bookmakers[0] && (
                  <>
                    <div className="flex flex-row gap-4">
                      <div className="flex-1 px-2 box h-18 w-40">
                        <label>Time</label>
                        <input
                          value={(() => {
                            const date = new Date(odd.commence_time);
                            const options = {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                              timeZone: "America/Halifax",
                            };
                            const dateString = date
                              .toLocaleDateString("en-US", options)
                              .split("/")
                              .join("-");
                            const timeOptions = {
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                              timeZone: "America/Halifax",
                            };
                            const timeString = date.toLocaleTimeString(
                              "en-US",
                              timeOptions
                            );
                            return `${dateString} ${timeString}`;
                          })()}
                          name={`time`}
                          className="bg-gray-800 text-white p-2 rounded w-full"
                          disabled={true}
                        />
                      </div>
                      <div className="flex-1 px-2 box h-18 w-40">
                        <label htmlFor="seasonType">Season Type</label>
                        <select
                          id="seasonType"
                          name="seasonType"
                          className="bg-gray-800 text-white p-2 rounded w-full"
                        >
                          <option value="">Select a Season</option>
                          <option value="Pre Season">Pre Season</option>
                          <option value="Regular Season">Regular Season</option>
                          <option value="Playoffs">Playoffs</option>
                        </select>
                      </div>
                    </div>

                    <div className="flex gap-2 mt-3">
                      <div className="flex-1 px-2 box h-18">
                        <label>Visitor Team</label>
                        <input
                          name={`visitorTeam`}
                          value={headerOptions[odd.away_team] || odd.away_team}
                          className="bg-gray-800 text-white p-2 rounded w-full text-center"
                          disabled={true}
                        />
                      </div>
                      <div className="flex-1 px-2 box h-18">
                        <label>V Sprd</label>
                        <input
                          type="number"
                          name={`vSprd`}
                          value={
                            odd.away_team ==
                            odd.bookmakers[0]?.markets[1]?.outcomes[0]?.name
                              ? odd.bookmakers[0]?.markets[1]?.outcomes[0]
                                  ?.point
                              : odd.bookmakers[0]?.markets[1]?.outcomes[1]
                                  ?.point
                          }
                          className="bg-gray-800 text-white p-2 rounded w-full text-center"
                          disabled={true}
                        />
                      </div>
                      <div className="flex-1 px-2 box h-18">
                        <label>V Sprd Odds</label>
                        <input
                          type="number"
                          value={(() => {
                            const price =
                              odd.away_team ==
                              odd.bookmakers[0]?.markets[1]?.outcomes[0]?.name
                                ? odd.bookmakers[0]?.markets[1]?.outcomes[0]
                                    ?.price
                                : odd.bookmakers[0]?.markets[1]?.outcomes[1]
                                    ?.price;
                            return Number.isInteger(price)
                              ? price / 100
                              : price;
                          })()}
                          name={`vSprdOdds`}
                          className="bg-gray-800 text-white p-2 rounded w-full text-center"
                          disabled={true}
                        />
                      </div>
                      <div className="flex-1 px-2 box h-18">
                        <label>Visitor M/L</label>
                        <input
                          type="number"
                          name={`vML`}
                          value={(() => {
                            const price =
                              odd.away_team ==
                              odd.bookmakers[0]?.markets[0]?.outcomes[0]?.name
                                ? odd.bookmakers[0]?.markets[0]?.outcomes[0]
                                    ?.price
                                : odd.bookmakers[0]?.markets[0]?.outcomes[1]
                                    ?.price;
                            return Number.isInteger(price)
                              ? price / 100
                              : price;
                          })()}
                          className="bg-gray-800 text-white p-2 rounded w-full text-center"
                          disabled={true}
                        />
                      </div>
                      <div className="flex-1 px-2 box h-18">
                        <label>V O/U</label>
                        <input
                          type="number"
                          name={`vOU`}
                          value={(() => {
                            const point =
                              odd.bookmakers[0]?.markets[2]?.outcomes[0]?.point;
                            return Number.isInteger(point)
                              ? point + 0.5
                              : point;
                          })()}
                          className="bg-gray-800 text-white p-2 rounded w-full text-center"
                          disabled={true}
                        />
                      </div>
                      <div className="flex-1 px-2 box h-18">
                        <label>V O/U Odds</label>
                        <input
                          type="number"
                          value={(() => {
                            const price =
                              odd.bookmakers[0]?.markets[2]?.outcomes[0]?.price;
                            return Number.isInteger(price)
                              ? price / 100
                              : price;
                          })()}
                          name={`vOUOdds`}
                          className="bg-gray-800 text-white p-2 rounded w-full text-center"
                          disabled={true}
                        />
                      </div>
                    </div>

                    <div className="flex gap-2 mt-1  ">
                      <div
                        className="box box h-18 w-60"
                        style={{ marginLeft: "0px" }}
                      >
                        <label>Home Team</label>
                        <input
                          name={`homeTeam`}
                          value={headerOptions[odd.home_team] || odd.home_team}
                          className="bg-gray-800 text-white p-2 rounded w-full text-center"
                          disabled={true}
                        />
                      </div>
                      <div className="px-2 box box h-18">
                        <label>H Sprd</label>
                        <input
                          type="number"
                          name={`hSprd`}
                          value={
                            odd.home_team ==
                            odd.bookmakers[0]?.markets[1]?.outcomes[1]?.name
                              ? odd.bookmakers[0]?.markets[1]?.outcomes[1]
                                  ?.point
                              : odd.bookmakers[0]?.markets[1]?.outcomes[0]
                                  ?.point
                          }
                          className="bg-gray-800 text-white p-2 rounded w-full text-center"
                          disabled={true}
                        />
                      </div>
                      <div className="px-2 box box h-18">
                        <label>H Sprd Odds</label>
                        <input
                          type="number"
                          name={`hSprdOdds`}
                          value={(() => {
                            const price =
                              odd.home_team ==
                              odd.bookmakers[0]?.markets[1]?.outcomes[1]?.name
                                ? odd.bookmakers[0]?.markets[1]?.outcomes[1]
                                    ?.price
                                : odd.bookmakers[0]?.markets[1]?.outcomes[0]
                                    ?.price;
                            return Number.isInteger(price)
                              ? price / 100
                              : price;
                          })()}
                          className="bg-gray-800 text-white p-2 rounded w-full text-center"
                          disabled={true}
                        />
                      </div>
                      <div className="px-2 box box h-18">
                        <label>Home M/L</label>
                        <input
                          type="number"
                          name={`hML`}
                          value={(() => {
                            const price =
                              odd.home_team ==
                              odd.bookmakers[0]?.markets[0]?.outcomes[1]?.name
                                ? odd.bookmakers[0]?.markets[0]?.outcomes[1]
                                    ?.price
                                : odd.bookmakers[0]?.markets[0]?.outcomes[0]
                                    ?.price;
                            return Number.isInteger(price)
                              ? price / 100
                              : price;
                          })()}
                          className="bg-gray-800 text-white p-2 rounded w-full text-center"
                          disabled={true}
                        />
                      </div>
                      <div className="px-2 box box h-18">
                        <label>H O/U</label>
                        <input
                          type="number"
                          name={`hOU`}
                          value={(() => {
                            const point =
                              odd.bookmakers[0]?.markets[2]?.outcomes[1]?.point;
                            return Number.isInteger(point)
                              ? point + 0.5
                              : point;
                          })()}
                          className="bg-gray-800 text-white p-2 rounded w-full text-center"
                          disabled={true}
                        />
                      </div>
                      <div className="px-2 box box h-18">
                        <label>H O/U Odds</label>
                        <input
                          type="number"
                          value={(() => {
                            const price =
                              odd.bookmakers[0]?.markets[2]?.outcomes[1]?.price;
                            return Number.isInteger(price)
                              ? price / 100
                              : price;
                          })()}
                          name={`hOUOdds`}
                          className="bg-gray-800 text-white p-2 rounded w-full text-center"
                          disabled={true}
                        />
                      </div>
                    </div>
                  </>
                )}
            </div>
          ))
        ) : (
          <p className="text-white">No valid games to display.</p>
        )}

        <br />
        <Button
          type="submit"
          variant="contained"
          style={{
            backgroundColor: "#FFD700",
            color: "rgba(0, 0, 0, 1)",
          }}
          disabled={!odds || odds.length === 0}
        >
          Submit ✔
        </Button>
      </form>
    </div>
  );
};

export default GameForm;
