import React, { useEffect, useState } from "react";
import "./Styles.css";
import { useLeagueContext } from "../LeagueContext";
import { getUserById } from "../../Apis/auth";
import { getGamesPlayedByDateRange } from "../../Apis/predictions";
import { headerOptions } from "./data";
import { getStateAbbreviation } from "../../utils/stateAbbreviations";

const calculateReg = (row) => {
  return row.result?.endingsPoints?.pickRegulation || 0;
};

const calculateOT = (row) => {
  return row.result?.endingsPoints?.pickOverTime || 0;
};

// Utility functions for calculations
const calculateWinStreak = (games) => {
  let maxStreak = 0,
    currentStreak = 0;
  // Sort games by date in ascending order (oldest first)
  const sortedGames = games.sort((a, b) => new Date(a.date) - new Date(b.date));

  sortedGames.forEach((game) => {
    const ml = parseFloat(
      game.result?.vegasOdds?.pickingFavorite ||
        game.result?.vegasOdds?.pickingUnderdog ||
        0
    );
    if (ml > 0) {
      currentStreak++;
      maxStreak = Math.max(maxStreak, currentStreak);
    } else {
      currentStreak = 0;
    }
  });
  return maxStreak;
};

const calculateLossStreak = (games) => {
  let maxStreak = 0,
    currentStreak = 0;
  // Sort games by date in ascending order (oldest first)
  const sortedGames = games.sort((a, b) => new Date(a.date) - new Date(b.date));

  sortedGames.forEach((game) => {
    const ml = parseFloat(
      game.result?.vegasOdds?.pickingFavorite ||
        game.result?.vegasOdds?.pickingUnderdog ||
        0
    );
    if (ml <= 0) {
      currentStreak++;
      maxStreak = Math.max(maxStreak, currentStreak);
    } else {
      currentStreak = 0;
    }
  });
  return maxStreak;
};

const calculateCurrentStreak = (games) => {
  let streak = "";
  let currentStreak = 0;
  let isWinStreak = false;

  // Sort games by date in ascending order (oldest first)
  const sortedGames = games.sort((a, b) => new Date(a.date) - new Date(b.date));

  for (let i = sortedGames.length - 1; i >= 0; i--) {
    const game = sortedGames[i];
    const ml = parseFloat(
      game.result?.vegasOdds?.pickingFavorite ||
        game.result?.vegasOdds?.pickingUnderdog ||
        0
    );

    if (ml > 0) {
      if (currentStreak === 0 || isWinStreak) {
        currentStreak++;
        isWinStreak = true;
      } else {
        break;
      }
    } else {
      if (currentStreak === 0 || !isWinStreak) {
        currentStreak++;
        isWinStreak = false;
      } else {
        break;
      }
    }
  }

  if (isWinStreak) {
    streak = `W${currentStreak}`;
  } else {
    streak = `L${currentStreak}`;
  }

  return streak;
};
const calculatePointsPercentage = (games, condition) => {
  const totalPoints = games.reduce(
    (acc, game) => acc + (game[condition] || 0),
    0
  );
  const maxPoints = games.length * 100; // Assume max points per condition is 100
  return ((totalPoints / maxPoints) * 100).toFixed(2);
};

const calculateL10 = (games) => {
  const last10Games = games.slice(-10);
  let winCount = 0,
    lossCount = 0;
  last10Games.forEach((game) => {
    if (game.BR > 0) winCount++;
    else lossCount++;
  });
  return `${winCount}/${lossCount}`;
};

const calculateFavoritePointsPercentage = (games) => {
  const favoritePoints = games.reduce((acc, game) => {
    const favoriteValue = parseFloat(
      game.result?.vegasOdds?.pickingFavorite || 0
    );
    return acc + (favoriteValue > 0 ? favoriteValue : 0);
  }, 0);
  const maxFavoritePoints = games.length * 100; // Assume max points for favorites is 100
  return ((favoritePoints / maxFavoritePoints) * 100).toFixed(2);
};

const calculateUnderdogPointsPercentage = (games) => {
  const underdogPoints = games.reduce((acc, game) => {
    const underdogValue = parseFloat(
      game.result?.vegasOdds?.pickingUnderdog || 0
    );
    return acc + (underdogValue > 0 ? underdogValue : 0);
  }, 0);
  const maxUnderdogPoints = games.length * 100; // Assume max points for underdogs is 100
  return ((underdogPoints / maxUnderdogPoints) * 100).toFixed(2);
};

const NFLStandingTables = () => {
  const { selectedLeague } = useLeagueContext();
  const [allPlayersData, setAllPlayersData] = useState([]);

  useEffect(() => {
    fetchAllPlayersData();
  }, [selectedLeague]);

  const fetchAllPlayersData = async () => {
    const endDate = new Date();
    const startDate = new Date(endDate);
    startDate.setDate(startDate.getDate() - 30);

    const formattedStartDate = startDate.toISOString().split("T")[0];
    const formattedEndDate = endDate.toISOString().split("T")[0];

    try {
      const response = await getGamesPlayedByDateRange(
        formattedStartDate,
        formattedEndDate
      );
      if (response.data && Array.isArray(response.data.data)) {
        const filteredData = response.data.data.filter(
          (game) => game.league === selectedLeague
        );
        const enhancedData = await Promise.all(
          filteredData.map(async (game) => {
            const userData = await getUserById(game.userId);
            return {
              ...game,
              co: userData.data.country || "-",
              state: userData.data.state || "-",
              city: userData.data.city || "-",
              player: userData.data.leagues[0]?.username || "-",
              BR:
                game.result?.perfectScore != null
                  ? parseFloat(game.result?.perfectScore).toFixed(2)
                  : "-",
              vegasOdds: game.result?.vegasOdds || {},
            };
          })
        );
        setAllPlayersData(enhancedData);
      }
    } catch (error) {
      console.error("Error fetching all players data:", error);
    }
  };

  const aggregatePlayerData = (data) => {
    const playerMap = {};

    data.forEach((game) => {
      if (!playerMap[game.userId]) {
        playerMap[game.userId] = {
          co: game.co,
          state: game.state,
          city: game.city,
          player: game.player,
          userId: game.userId,
          gamesPlayed: 0,
          totalBR: 0,
          uniqueNights: new Set(),
          wins: 0,
          losses: 0,
          oneS: 0,
          oneS0: 0,
          twoS0: 0,
          oneSW3: 0,
          twoSW3: 0,
          oneSW7: 0,
          twoSW7: 0,
          ml: 0,
          spread: 0,
          ou: 0,
          games: [],
        };
      }

      const player = playerMap[game.userId];
      player.gamesPlayed++;
      player.totalBR += parseFloat(game.BR) || 0;
      player.uniqueNights.add(
        game.date
          ? game.date.split("T")[0]
          : `game-${game.id || player.gamesPlayed}`
      );

      // Check ML value to determine win or loss
      const ml = parseFloat(
        game.result?.vegasOdds?.pickingFavorite ||
          game.result?.vegasOdds?.pickingUnderdog ||
          0
      );

      if (ml > 0) {
        player.wins += 1;
      } else {
        player.losses += 1;
      }

      player.oneS +=
        (game.result?.accuracyPoints?.home?.p1s || 0) +
        (game.result?.accuracyPoints?.vistor?.p1s || 0);
      player.oneS0 +=
        (game.result?.accuracyPoints?.home?.p1s0 || 0) +
        (game.result?.accuracyPoints?.vistor?.p1s0 || 0);
      player.twoS0 +=
        (game.result?.accuracyPoints?.home?.p2s0 || 0) +
        (game.result?.accuracyPoints?.vistor?.p2s0 || 0);
      player.oneSW3 +=
        (game.result?.accuracyPoints?.home?.p1s3p || 0) +
        (game.result?.accuracyPoints?.vistor?.p1s3p || 0);
      player.twoSW3 +=
        (game.result?.accuracyPoints?.home?.p2s3p || 0) +
        (game.result?.accuracyPoints?.vistor?.p2s3p || 0);
      player.oneSW7 +=
        (game.result?.accuracyPoints?.home?.p1s7p || 0) +
        (game.result?.accuracyPoints?.vistor?.p1s7p || 0);
      player.twoSW7 +=
        (game.result?.accuracyPoints?.home?.p2s7p || 0) +
        (game.result?.accuracyPoints?.vistor?.p2s7p || 0);

      player.ml += parseFloat(
        game.result?.vegasOdds?.pickingFavorite ||
          game.result?.vegasOdds?.pickingUnderdog ||
          0
      );
      player.spread += parseFloat(
        game.result?.vegasOdds?.pickingSpread?.vSpreadPoints ||
          game.result?.vegasOdds?.pickingSpread?.hSpreadPoints ||
          0
      );
      player.ou += parseFloat(
        game.result?.vegasOdds?.pickingOver ||
          game.result?.vegasOdds?.pickingUnder ||
          0
      );
      player.games.push(game);
    });

    return Object.values(playerMap);
  };

  const renderNFLColumns = (player) => {
    return (
      <>
        <td className="text-xs font-medium text-center">{player.co || "-"}</td>
        <td className="text-xs font-medium text-center">
          {getStateAbbreviation(player.state, player.co) || "-"}
        </td>
        <td className="text-xs font-medium text-center">
          {player.city || "-"}
        </td>
        <td
          className="text-xs font-medium text-center"
          style={{ color: "#dba418", fontSize: "17px" }}
        >
          {player.player || "-"}
        </td>
        <td className="text-xs font-medium text-center">
          {player.rank || "-"}
        </td>
        <td className="text-xs font-medium text-center">
          {((player.wins / player.gamesPlayed) * 100).toFixed(2)}%
        </td>
        <td className="text-xs font-medium text-center">
          {player.gamesPlayed}
        </td>
        <td className="text-xs font-medium text-center">
          {player.totalBR.toFixed(2)}
        </td>
        <td className="text-xs font-medium text-center">{player.wins}</td>
        <td className="text-xs font-medium text-center">{player.losses}</td>
        <td className="text-xs font-medium text-center">
          {calculateCurrentStreak(player.games)}
        </td>
        <td className="text-xs font-medium text-center">
          {calculateWinStreak(player.games)}
        </td>
        <td className="text-xs font-medium text-center">
          {calculateLossStreak(player.games)}
        </td>
        <td className="text-xs font-medium text-center">
          {player.oneS.toFixed(2)}
        </td>
        <td className="text-xs font-medium text-center">
          {player.oneS0.toFixed(2)}
        </td>
        <td className="text-xs font-medium text-center">
          {player.twoS0.toFixed(2)}
        </td>
        <td className="text-xs font-medium text-center">
          {player.oneSW3.toFixed(2)}
        </td>
        <td className="text-xs font-medium text-center">
          {player.twoSW3.toFixed(2)}
        </td>
        <td className="text-xs font-medium text-center">
          {player.oneSW7.toFixed(2)}
        </td>
        <td className="text-xs font-medium text-center">
          {player.twoSW7.toFixed(2)}
        </td>
        <td className="text-xs font-medium text-center">
          {player.ml.toFixed(2)}
        </td>
        <td className="text-xs font-medium text-center">
          {player.spread.toFixed(2)}
        </td>
        <td className="text-xs font-medium text-center">
          {player.ou.toFixed(2)}
        </td>
        <td className="text-xs font-medium text-center">
          {player.gamesPlayed > 0
            ? (player.totalBR / player.gamesPlayed).toFixed(2)
            : "0.00"}
        </td>
        <td className="text-xs font-medium text-center">
          {player.uniqueNights.size > 0
            ? (player.totalBR / player.uniqueNights.size).toFixed(2)
            : "0.00"}
        </td>
        <td className="text-xs font-medium text-center">
          {calculatePointsPercentage(player.games, "favoritePoints")}
        </td>
        <td className="text-xs font-medium text-center">
          {calculatePointsPercentage(player.games, "underdogPoints")}
        </td>
        <td className="text-xs font-medium text-center">
          {calculateReg(player) || "0"}
        </td>
        <td className="text-xs font-medium text-center">
          {calculateOT(player) || "-"}
        </td>
        <td className="text-xs font-medium text-center">
          {calculateL10(player.games)}
        </td>
        <td className="text-xs font-medium text-center">
          {calculateFavoritePointsPercentage(player.games)}%
        </td>
        <td className="text-xs font-medium text-center">
          {calculateUnderdogPointsPercentage(player.games)}%
        </td>
      </>
    );
  };

  const aggregatedPlayersData = aggregatePlayerData(allPlayersData);

  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            {headerOptions.NFL.map((item, ind) => (
              <th
                key={ind}
                className="text-xs font-medium"
                style={{
                  color: "#dba418",
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <div style={{ height: "15px" }}></div>
        <tbody>
          {aggregatedPlayersData.length > 0 ? (
            aggregatedPlayersData.map((player, index) => (
              <tr
                key={index}
                className="h-14 bg-[#181818] text-white row-slide-in"
                style={{
                  marginTop: "-30px",
                  animationDelay: `${index * 0.05}s`,
                }}
              >
                {renderNFLColumns(player)}
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={headerOptions.NFL.length}
                className="text-xs font-medium text-center"
              >
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default NFLStandingTables;
