import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SignInModal from "../../components/Modal/SignInModal";
import { Button, Container } from "@mui/material";

const TestLogin = () => {
  const [signInModalIsOpen, setSignInModalIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleSignIn = () => {
    setSignInModalIsOpen(false);
    navigate("/games");
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Button
        variant="contained"
        onClick={() => setSignInModalIsOpen(true)}
        sx={{
          backgroundColor: "#f6e05e",
          color: "#000",
          "&:hover": {
            backgroundColor: "#f0e68c",
          },
        }}
      >
        Sign In / Sign Up
      </Button>
      <SignInModal
        modalIsOpen={signInModalIsOpen}
        closeModal={() => setSignInModalIsOpen(false)}
        onSignIn={handleSignIn}
      />
    </Container>
  );
};

export default TestLogin;
