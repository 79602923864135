import { State, Country } from "country-state-city";

export function getStateAbbreviation(stateName, countryName = "") {
  if (!stateName) return "";

  // Check if the state name is already an abbreviation
  if (stateName.length <= 3) return stateName.toUpperCase();

  // Special case for Newfoundland and Labrador
  if (stateName.toLowerCase() === "newfoundland and labrador") return "NL";

  // Get country code
  const country = Country.getAllCountries().find(
    (c) => c.name.toLowerCase() === countryName.toLowerCase()
  );
  const countryCode = country ? country.isoCode : "";

  // Find the state
  const states = State.getStatesOfCountry(countryCode);
  const state = states.find(
    (s) => s.name.toLowerCase() === stateName.toLowerCase()
  );

  if (state && state.isoCode) {
    // If the state has an ISO code, use the last part (usually 2-3 characters)
    const parts = state.isoCode.split("-");
    return parts[parts.length - 1];
  }

  // If no matching state found or no ISO code, create an abbreviation
  return createAbbreviation(stateName);
}

function createAbbreviation(name) {
  // Split the name into words
  const words = name.split(/\s+/);

  if (words.length === 1) {
    // For single word, return first 3 letters
    return name.substring(0, 3).toUpperCase();
  } else {
    // For multiple words, use first letter of each word (up to 3)
    return words
      .slice(0, 3)
      .map((word) => word[0])
      .join("")
      .toUpperCase();
  }
}

export function getCountryCode(countryName) {
  const country = Country.getAllCountries().find(
    (c) => c.name.toLowerCase() === countryName.toLowerCase()
  );
  return country ? country.isoCode : "";
}
