import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Modal from "react-modal";

// import wish1 from "../assets/w1.png";
// import wish2 from "../assets/wish2.png";
// import wish3 from "../assets/wish3.png";
import wish1 from "../../../assets/w1.png";
import wish2 from "../../../assets/wish2.png";
import wish3 from "../../../assets/wish3.png";
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField } from "@material-ui/core";
import { addToWaitlist, verifyEmail } from "../../../Apis/waitlist";

// import { claimOffer } from "../../../Apis/auth"; // Import your API functions
import { claimOffer } from "../../../Apis/auth"; // Import your API functions
import ClaimRegistration from "./ClaimRegistration"; // adjust the path as necessary

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    borderRadius: "8px",
    border: "1px solid #BE8200",
    boxShadow: "0px 4px 40px 0px rgba(190, 130, 0, 0.60)",
    background: "#212227",
    padding: "30px",
  },
  overlay: {
    background: "rgba(33, 34, 39, 0.90)",
  },
};

const useStyles = makeStyles((theme) => ({
  wishlistContainer: {
    width: "100%",
    margin: "0 auto",
    textAlign: "center",
    backgroundColor: "black",
    minHeight: "100vh",
    color: "white",
    paddingBottom: "20px",
  },
  imageContainer: {
    width: "100%",
  },
  image: {
    width: "100%",
    height: "auto",
    display: "block",
  },
  grayBox: {
    backgroundColor: "gray",
    padding: "10px",
    marginTop: "15px",
    width: "100%",
    border: "1px solid white",
    maxWidth: "450px",
    margin: "10px auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "75%",
    },
  },
  yellowText: {
    color: "yellow",
    fontSize: "28px",
    fontWeight: "bold",
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  whiteText: {
    color: "white",
    fontSize: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  requestAccessCodeText: {
    color: "white",
    fontSize: "16px",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  enterEmailText: {
    color: "yellow",
    fontWeight: "bold",
    fontSize: "30px",
    fontFamily: "Arial, sans-serif",
    marginTop: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  emailFormContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "15px",
  },
  emailInput: {
    width: "50%",
    backgroundColor: "gray",
    [theme.breakpoints.down("sm")]: {
      width: "75%",
    },
    "& .MuiInputBase-root": {
      color: "white",
    },
    "& input::placeholder": {
      color: "white",
    },
  },
  otpInput: {
    width: "50%",
    backgroundColor: "gray",
    [theme.breakpoints.down("sm")]: {
      width: "75%",
    },
    "& .MuiInputBase-root": {
      color: "white",
    },
    "& input::placeholder": {
      color: "white",
    },
  },
  submitButton: {
    margin: "30px 0px",
    backgroundColor: "yellow",
    color: "black",
    "&:hover": {
      backgroundColor: "#f1c40f",
    },
  },
}));

const Wishlist = () => {
  const classes = useStyles();
  const [otpEnabled, setOtpEnabled] = useState(false);
  const [buttonText, setButtonText] = useState("Send Access Code");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [emailDisabled, setEmailDisabled] = useState(false);
  const [timer, setTimer] = useState(300); // Update initial timer state to 300 seconds for 5 minutes
  const [resendAvailable, setResendAvailable] = useState(false);
  const [otpInputs, setOtpInputs] = useState(Array(6).fill(""));
  const [email, setEmail] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false); // State to handle modal visibility
  const [labelText, setLabelText] = useState(
    "PLEASE ENTER YOUR EMAIL ADDRESS SO WE CAN SEND YOU AN ACCESS CODE THAT YOU WILL NEED TO ACTIVATE YOUR FREE LIFETIME MEMBERSHIP!"
  );
  const [generatedOtp, setGeneratedOtp] = useState(""); // State to store the generated OTP

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    let intervalId;

    if (otpEnabled && timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(intervalId);
            setButtonDisabled(false); // Enable the button when time is up
            setResendAvailable(true);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [otpEnabled, timer]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!otpEnabled) {
      // Send OTP
      setButtonDisabled(true); // Disable button only when sending OTP
      try {
        const response = await claimOffer({ email });
        console.log("OTP Verification Response:", response);
        setGeneratedOtp(response.data.otp); // Store the generated OTP

        Swal.fire({
          title: "Access Code Sent",
          text: "Please check your email for the Access code.",
          icon: "success",
          color: "white",
          timer: 5000,
          timerProgressBar: true,
          didClose: () => {
            setOtpEnabled(true);
            setButtonText("CONTINUE");
            setEmailDisabled(true); // Keep email disabled
            setTimer(300); // Reset the timer to 5 minutes
            setResendAvailable(false);
          },
        });
      } catch (error) {
        console.error("An error occurred while sending OTP:", error);
        Swal.fire({
          title: "Error",
          text: "An error occurred while sending OTP.",
          icon: "error",
          color: "white",
        });
      }
      setButtonDisabled(false); // Re-enable button after sending OTP
    } else {
      // Verify OTP
      setButtonDisabled(true); // Disable button only when verifying OTP
      const enteredOtp = otpInputs.join("");
      if (enteredOtp === generatedOtp) {
        console.log("OTP Verified Successfully!");
        openModal(); // Open the ClaimRegistration modal
      } else {
        Swal.fire({
          title: "Invalid OTP",
          text: "The OTP you entered is incorrect. Please try again.",
          icon: "error",
          color: "white",
        });
      }
      setButtonDisabled(false); // Re-enable the button after verification
    }
  };

  const handleResendCode = async () => {
    if (resendAvailable) {
      setButtonDisabled(true); // Disable button during resend
      try {
        const response = await claimOffer({ email });
        console.log("New OTP Sent:", response);
        setGeneratedOtp(response.data.otp);
        setTimer(300);
        setOtpEnabled(true);
        setButtonText("CONTINUE");
        setResendAvailable(false);

        Swal.fire({
          title: "OTP Resent",
          text: "A new OTP has been sent to your email.",
          color: "white",
          icon: "info",
          timer: 2000,
          timerProgressBar: true,
        });
      } catch (error) {
        console.error("Error resending OTP:", error);
        Swal.fire({
          title: "Error",
          text: "Failed to resend OTP. Please try again.",
          icon: "error",
          color: "white",
        });
      }
      setButtonDisabled(false); // Re-enable button after resending OTP
    } else {
      Swal.fire({
        title: "Cannot Resend Code",
        text: "Please wait until the timer expires before requesting a new code.",
        icon: "warning",
        color: "white",
      });
    }
  };

  const handleChangeEmail = () => {
    setOtpEnabled(false);
    setEmailDisabled(false);
    setTimer(300); // Reset the timer to 5 minutes
    setButtonText("Send Access Code");
    setButtonDisabled(false);
    setResendAvailable(false);
    setLabelText(
      "PLEASE ENTER YOUR EMAIL ADDRESS SO WE CAN SEND YOU AN ACCESS CODE THAT YOU WILL NEED TO ACTIVATE YOUR FREE LIFETIME MEMBERSHIP!"
    );
  };

  const formatTime = (time) => {
    if (isNaN(time) || time < 0) {
      return "00:00";
    }
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  // Function to handle changes in OTP input fields
  const handleOtpChange = (e, index) => {
    const newOtpInputs = [...otpInputs];
    newOtpInputs[index] = e.target.value;
    setOtpInputs(newOtpInputs);

    // Automatically move to next input field after a digit is entered
    if (e.target.value.length === 1 && index < 5) {
      document.getElementById(`otp-${index + 1}`).focus();
    }
  };

  // Function to handle backspace key events in OTP input fields
  const handleOtpBackspace = (e, index) => {
    if (e.key === "Backspace" && !otpInputs[index] && index > 0) {
      document.getElementById(`otp-${index - 1}`).focus();
    }
  };

  return (
    <div className={classes.wishlistContainer}>
      <div className={classes.imageContainer}>
        <img src={wish1} alt="Wishlist Image" className={classes.image} />
      </div>

      <div className={classes.grayBox}>
        <div className={classes.yellowText}>JOIN THE WAITLIST NOW</div>
        <div className={classes.whiteText}>
          And Get Notified When We're Ready!
        </div>
      </div>

      <div className={classes.imageContainer}>
        <img src={wish2} alt="Wishlist Image 2" className={classes.image} />
      </div>

      <div
        className={classes.requestAccessCodeText}
        style={{ padding: "20px" }}
      >
        REQUEST YOUR EMAIL ALL-ACCESS CODE
      </div>
      <form className="claim" onSubmit={handleSubmit}>
        <label className="label-text-claim">{labelText}</label>
        <br />
        <span className="input-span-claim">
          <label htmlFor="email" className="label-claim">
            EMAIL
          </label>
          <input
            type="email"
            name="email-claim"
            id="email"
            required
            disabled={emailDisabled}
            className={emailDisabled ? "disabled" : ""}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </span>

        {otpEnabled && (
          <>
            <br />
            <hr />
            <br />
            <label className="label-claim">ENTER YOUR ACCESS CODE BELOW</label>

            <div className="otp-input-container">
              {[0, 1, 2, 3, 4, 5].map((i) => (
                <input
                  key={i}
                  id={`otp-${i}`}
                  type="text"
                  maxLength="1"
                  onChange={(e) => handleOtpChange(e, i)}
                  onKeyDown={(e) => handleOtpBackspace(e, i)}
                  disabled={!otpEnabled}
                  className="otp-input"
                />
              ))}
            </div>
          </>
        )}

        <input
          className={`submit-claim ${buttonDisabled ? "disabled" : ""}`}
          type="submit"
          value={buttonText}
          disabled={buttonDisabled}
        />

        {otpEnabled && (
          <div className="timer">
            Time left: {formatTime(timer)}
            {resendAvailable && (
              <button
                className="resend-code-button"
                type="button"
                onClick={handleResendCode}
              >
                Resend Code
              </button>
            )}
          </div>
        )}
      </form>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={customModalStyles}
      >
        <div>
          <button onClick={openModal}>Open Registration</button>
          <ClaimRegistration
            modalIsOpen={modalIsOpen}
            closeModal={closeModal}
            email={email} // Pass email as a prop
          />
        </div>
      </Modal>

      <div className={classes.imageContainer}>
        <img src={wish3} alt="Wishlist Image 3" className={classes.image} />
      </div>
    </div>
  );
};

export default Wishlist;
