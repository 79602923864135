import React, { useEffect, useState, forwardRef } from "react";
import "./tableComponent.css";
import { useLeagueContext } from "../LeagueContext";
import TimeFormat from "../../services/TimeFormat";
import { getUserById } from "../../Apis/auth";
import { getGamePlayedByUserId } from "../../Apis/predictions";
import DatePicker from "react-datepicker";
// import CloseIcon from "@mui/icons-material/CancelOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { headerOptions } from "./data"; // Import headerOptions
import { TextField, InputAdornment, IconButton } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CancelOutlined from "@mui/icons-material/CancelOutlined";
import ArrowForward from "@mui/icons-material/ArrowForward";
import Loader from "../Loader/Loader"; // Import the custom Loader component
import CalendarTodayIcon from "@mui/icons-material/CalendarToday"; // Import MUI Calendar Icon
import ArrowBack from "@mui/icons-material/ArrowBack";
import UBRbg from "../../assets/UBR-bg.png";
import {
  getStateAbbreviation,
  getCountryCode,
} from "../../utils/stateAbbreviations";

const calculateReg = (row) => row.result?.endingsPoints?.pickRegulation || 0;
const calculateOT = (row) => row.result?.endingsPoints?.pickOverTime || 0;
const calculateSO = (row) => row.result?.endingsPoints?.pickShootout || 0;
const calculateEI = (row) => row.result?.endingsPoints?.pickExtraInnings || 0;
console.log(calculateEI);

const calculateMetrics = (row, league) => {
  // const homeP1S = row.result?.accuracyPoints?.home?.p1s || 0;
  // const visitorP1S = row.result?.accuracyPoints?.vistor?.p1s || 0;

  // console.log("Home p1s:", homeP1S);
  // console.log("Visitor p1s:", visitorP1S);

  // const oneS = (homeP1S + visitorP1S).toFixed(2);
  // console.log("OneS:", oneS);

  const oneS = (
    (row.result?.accuracyPoints?.home?.p1s || 0) +
    (row.result?.accuracyPoints?.vistor?.p1s || 0)
  ).toFixed(2);
  // console.log("OneS:", oneS);

  const oneSO = (
    (row.result?.accuracyPoints?.home?.p1s0 || 0) +
    (row.result?.accuracyPoints?.vistor?.p1s0 || 0)
  ).toFixed(2);
  // console.log("oneSO:", oneSO);

  const oneSW2 = (
    (row.result?.accuracyPoints?.home?.p1s2p || 0) +
    (row.result?.accuracyPoints?.vistor?.p1s2p || 0)
  ).toFixed(2);
  // console.log("oneSW2:", oneSW2);

  const twoSW2 = (
    (row.result?.accuracyPoints?.home?.p2s2p || 0) +
    (row.result?.accuracyPoints?.vistor?.p2s2p || 0)
  ).toFixed(2);
  // console.log("twoSW2:", twoSW2);

  const oneSW3 = (
    (row.result?.accuracyPoints?.home?.p1s3p || 0) +
    (row.result?.accuracyPoints?.vistor?.p1s3p || 0)
  ).toFixed(2);
  // console.log("oneSW3:", oneSW3);

  const twoSW3 = (
    (row.result?.accuracyPoints?.home?.p2s3p || 0) +
    (row.result?.accuracyPoints?.vistor?.p2s3p || 0)
  ).toFixed(2);
  // console.log("twoSW3:", twoSW3);

  const oneSW7 = (
    (row.result?.accuracyPoints?.home?.p1s7p || 0) +
    (row.result?.accuracyPoints?.vistor?.p1s7p || 0)
  ).toFixed(2);
  // console.log("oneSW7:", oneSW7);

  const twoSW7 = (
    (row.result?.accuracyPoints?.home?.p2s7p || 0) +
    (row.result?.accuracyPoints?.vistor?.p2s7p || 0)
  ).toFixed(2);
  // console.log("twoSW7:", twoSW7);

  switch (league) {
    case "NHL":
      return { oneS, oneSO };
    case "NBA":
      return { oneS, oneSO, oneSW3, twoSW3, oneSW7, twoSW7 };
    case "MLB":
      return { oneS, oneSO, oneSW2, twoSW2 };
    case "NFL":
      return { oneS, oneSO, oneSW3, twoSW3, oneSW7, twoSW7 };
    default:
      return {};
  }
};

const TableComponent = ({ gameData }) => {
  const { selectedLeague } = useLeagueContext();
  const [filteredHeaderOptions, setFilteredHeaderOptions] = useState([]);
  const [gamesPlayed, setGamesPlayed] = useState([]);
  const [gameDataMap, setGameDataMap] = useState({});
  const id = localStorage.getItem("_id");
  const [selectedDate, setSelectedDate] = useState(() => {
    // Get current date in UTC
    const now = new Date();
    
    // Convert to EST/EDT (UTC-4)
    const estOffset = -4; // EST/EDT offset from UTC
    const utc = now.getTime() + (now.getTimezoneOffset() * 60000);
    const estDate = new Date(utc + (3600000 * estOffset));
    
    // Return date formatted as YYYY-MM-DD
    return estDate;
  });
  const [isLoading, setIsLoading] = useState(false);

  const getUser = () => {
    return getUserById(id).then((res) => res.data);
  };
  // Function to move the date backward by one day
  const handlePreviousDay = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() - 1);
    setSelectedDate(newDate.toISOString().split("T")[0]);
    setIsLoading(true);
  };

  // Function to move the date forward by one day
  const handleNextDay = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() + 1);
    setSelectedDate(newDate.toISOString().split("T")[0]);
    setIsLoading(true);
  };

  // Function to check if a date is in the future
  const isFutureDate = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return new Date(date) > today;
  };

  const getResult = (userData) => {
    setIsLoading(true);
    getGamePlayedByUserId(id)
      .then((res) => {
        console.log("Full response:", res);
        console.log("Response data:", res.data);

        if (res.data && res.data.data) {
          console.log("Games played:", res.data.data.gamesPlayed);
          console.log("Game data:", res.data.data.gameData);

          const gamesPlayed = Array.isArray(res.data.data.gamesPlayed)
            ? res.data.data.gamesPlayed
            : [];
          const gameDataArray = Array.isArray(res.data.data.gameData)
            ? res.data.data.gameData
            : [];

          console.log("Processed games played:", gamesPlayed);
          console.log("Processed game data:", gameDataArray);

          const gameDataLookup = {};
          gameDataArray.forEach((game) => {
            if (game && game._id) {
              gameDataLookup[game._id] = game;
            } else {
              console.warn("Invalid game data:", game);
            }
          });

          setGameDataMap(gameDataLookup);

          const filteredGamesPlayed = gamesPlayed.filter((game) => {
            if (!game || !game.createdAt) {
              console.warn("Invalid game:", game);
              return false;
            }

            const gameDate = new Date(game.createdAt);
            gameDate.setHours(0, 0, 0, 0);
            const selected = new Date(selectedDate);
            selected.setHours(0, 0, 0, 0);

            return (
              game.league === selectedLeague &&
              gameDate.getTime() === selected.getTime()
            );
          });

          const enhancedData = filteredGamesPlayed.map((playedGame) => {
            const correspondingGame = gameDataLookup[playedGame._id] || {};
            return {
              ...playedGame,
              ...correspondingGame,
              co: userData.country || "-",
              state: userData.state || "-",
              city: userData.city || "-",
              player: userData.leagues[0]?.username || "-",
              BR:
                playedGame?.result?.perfectScore != null
                  ? parseFloat(playedGame?.result?.perfectScore).toFixed(2)
                  : "-",
              vegasOdds: playedGame?.result?.vegasOdds || {},
            };
          });

          console.log("Enhanced data:", enhancedData);
          setGamesPlayed(enhancedData);
        } else {
          console.error("Unexpected response structure:", res);
          setGamesPlayed([]);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching game data:", error);
        setIsLoading(false);
        setGamesPlayed([]);
      });
  };
  const isValidDate = (date) => {
    return !isNaN(new Date(date).getTime());
  };

  // Function to format date as dd/mm/yyyy
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate().toString().padStart(2, "0")}/${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`;
  };

  useEffect(() => {
    if (selectedLeague && headerOptions[selectedLeague]) {
      setFilteredHeaderOptions(headerOptions[selectedLeague]);
    } else {
      setFilteredHeaderOptions([]);
    }

    getUser().then((userData) => {
      getResult(userData);
    });
  }, [selectedLeague, selectedDate]);

  // Function to calculate TP (Total Points)
  const calculateTP = (games) => {
    return games.map((row) => {
      const tp = parseFloat(
        (row.result?.accuracyPoints?.home?.p1s || 0) +
          (row.result?.accuracyPoints?.vistor?.p1s || 0) +
          (row.result?.accuracyPoints?.home?.p1s2p || 0) +
          (row.result?.accuracyPoints?.vistor?.p1s2p || 0) +
          (row.result?.accuracyPoints?.home?.p2s2p || 0) +
          (row.result?.accuracyPoints?.vistor?.p2s2p || 0) +
          (row.result?.vegasOdds?.pickingFavorite ||
            row.result?.vegasOdds?.pickingUnderdog ||
            0) +
          (row.result?.vegasOdds?.pickingOver ||
            row.result?.vegasOdds?.pickingUnder ||
            0) +
          (row.result?.vegasOdds?.pickingSpread?.vSpreadPoints ||
            row.result?.vegasOdds?.pickingSpread?.hSpreadPoints ||
            0) +
          (row.BR || 0)
      ).toFixed(2);
      return { ...row, tp };
    });
  };

  // Function to sort games by time (from the game's time value)
  const sortGamesByTime = (games) => {
    return games.sort((a, b) => {
      // Assuming `gameData?.time` is in a format that can be converted to Date
      const timeA = new Date(a?.gameData?.time);
      const timeB = new Date(b?.gameData?.time);
      return timeB - timeA; // Sort in descending order (latest time first)
    });
  };

  // Main function to calculate TP and Rank (time sorted)
  const calculateTPandRank = (games) => {
    const gamesWithTP = calculateTP(games); // Calculate TP
    const timeSortedGames = sortGamesByTime(gamesWithTP); // Sort by time

    const ranks = timeSortedGames.map((game, index) => index + 1); // Assign ranks
    return { sortedGames: timeSortedGames, ranks };
  };

  // Example usage
  const { sortedGames, ranks } = calculateTPandRank(gamesPlayed);

  const renderColumns = (row) => {
    const Reg = calculateReg(row);
    const OT = calculateOT(row);
    const SO = calculateSO(row);
    const EI = calculateEI(row);

    switch (selectedLeague) {
      case "NHL":
        return (
          <>
            <td
              className="text-xs font-medium text-center"
              style={{
                border: "2px solid black",
                color: parseFloat(Reg) === 0 ? "red" : "White",
                backgroundColor:
                  parseFloat(Reg) !== 0 ? "green" : "transparent",
                textShadow:
                  parseFloat(Reg) !== 0 ? "2px 2px 3px black" : "none",
              }}
            >
              {parseFloat(Reg) === 0 ? (
                <CloseIcon style={{ color: "red", fontSize: "2.5em" }} />
              ) : (
                Reg
              )}
            </td>
            <td
              className="text-xs font-medium text-center"
              style={{
                border: "2px solid black",
                color: parseFloat(OT) === 0 ? "red" : "White",
                backgroundColor: parseFloat(OT) !== 0 ? "green" : "transparent",
                textShadow: parseFloat(OT) !== 0 ? "2px 2px 3px black" : "none",
              }}
            >
              {parseFloat(OT) === 0 ? (
                <CloseIcon style={{ color: "red", fontSize: "2.5em" }} />
              ) : (
                OT
              )}
            </td>
            <td
              className="text-xs font-medium text-center"
              style={{
                border: "2px solid black",
                color: parseFloat(SO) === 0 ? "red" : "White",
                backgroundColor: parseFloat(SO) !== 0 ? "green" : "transparent",
                textShadow: parseFloat(SO) !== 0 ? "2px 2px 3px black" : "none",
              }}
            >
              {parseFloat(SO) === 0 ? (
                <CloseIcon style={{ color: "red", fontSize: "2.5em" }} />
              ) : (
                SO
              )}
            </td>
          </>
        );
      case "NBA":
      case "NFL":
        return (
          <>
            <td
              className="text-xs font-medium text-center"
              style={{
                border: "2px solid black",
                color: parseFloat(Reg) === 0 ? "red" : "gold",
                backgroundColor:
                  parseFloat(Reg) !== 0 ? "green" : "transparent",
                textShadow:
                  parseFloat(Reg) !== 0 ? "2px 2px 3px black" : "none",
              }}
            >
              {parseFloat(Reg) === 0 ? (
                <CloseIcon style={{ color: "red", fontSize: "2.5em" }} />
              ) : (
                Reg
              )}
            </td>
            <td
              className="text-xs font-medium text-center"
              style={{
                border: "2px solid black",
                color: parseFloat(OT) === 0 ? "red" : "gold",
                backgroundColor: parseFloat(OT) !== 0 ? "green" : "transparent",
                textShadow: parseFloat(OT) !== 0 ? "2px 2px 3px black" : "none",
              }}
            >
              {parseFloat(OT) === 0 ? (
                <CloseIcon style={{ color: "red", fontSize: "2.5em" }} />
              ) : (
                OT
              )}
            </td>
          </>
        );
      case "MLB":
        return (
          <>
            <td
              className="text-xs font-medium text-center"
              style={{
                border: "2px solid black",
                color: parseFloat(Reg) === 0 ? "red" : "gold",
                backgroundColor:
                  parseFloat(Reg) !== 0 ? "green" : "transparent",
                textShadow:
                  parseFloat(Reg) !== 0 ? "2px 2px 3px black" : "none",
              }}
            >
              {parseFloat(Reg) === 0 ? (
                <CloseIcon style={{ color: "red", fontSize: "2.5em" }} />
              ) : (
                Reg
              )}
            </td>
            <td
              className="text-xs font-medium text-center"
              style={{
                border: "2px solid black",
                color: parseFloat(EI) === 0 ? "red" : "gold",
                backgroundColor: parseFloat(EI) !== 0 ? "green" : "transparent",
                textShadow: parseFloat(EI) !== 0 ? "2px 2px 3px black" : "none",
              }}
            >
              {parseFloat(EI) === 0 ? (
                <CloseIcon style={{ color: "red", fontSize: "2.5em" }} />
              ) : (
                EI
              )}
            </td>
          </>
        );
      default:
        return null;
    }
  };
  // Custom Input with Calendar Icon
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <TextField
      ref={ref}
      onClick={onClick}
      value={value}
      variant="outlined"
      fullWidth
      style={{ borderRadius: "10px", height: "56px", width: "190px" }} // Styling for the input field
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={onClick}>
              <CalendarTodayIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  ));

  const renderCell = (item, column) => {
    if (column.key === "state") {
      return getStateAbbreviation(item[column.key], item.co) || "-";
    }
    if (column.key === "co") {
      return getCountryCode(item[column.key]) || item[column.key] || "-";
    }
    // ... rest of the renderCell function ...
  };

  return (
    <div className="table-container">
      <div className="date-filter-container mb-3">
        <div className="row">
          <div className="col-md-3">
            <label
              htmlFor="selectedDate"
              className="form-label"
              style={{
                color: "#dba418",
                marginLeft: "70px",
                fontWeight: "bold",
                marginBottom: "-5px",
              }}
            >
              D a t e :
            </label>
            <div className="input-group">
              <button
                className="btn btn-secondary"
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: " 10px 10px",
                }}
                onClick={handlePreviousDay}
              >
                <ArrowBack style={{ fontSize: "1.5rem", color: "#dba418" }} />{" "}
              </button>
              <div>
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => {
                    // Ensure the new date is also in EST/EDT
                    const utc = date.getTime() + (date.getTimezoneOffset() * 60000);
                    const estDate = new Date(utc + (3600000 * -4)); // EST/EDT offset
                    setSelectedDate(estDate);
                  }}
                  dateFormat="MM/dd/yyyy"
                  customInput={<CustomInput />} // Use custom input with calendar icon
                  style={{ border: "none" }}
                />
              </div>
              <button
                className="btn btn-secondary"
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "10px 10px",
                }}
                onClick={handleNextDay}
                disabled={isFutureDate(selectedDate)}
              >
                <ArrowForward
                  style={{ fontSize: "1.5rem", color: "#dba418" }}
                />{" "}
              </button>
            </div>
          </div>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            {filteredHeaderOptions.map((item, ind) => (
              <th
                key={ind}
                style={{
                  color: "#dba418",
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
                className="text-xs font-medium"
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <div style={{ height: "15px" }}></div>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={filteredHeaderOptions.length}>
                <div
                  style={{
                    position: "relative",
                    height: "100px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ transform: "scale(0.5)" }}>
                    <Loader />
                  </div>
                </div>
              </td>
            </tr>
          ) : sortedGames.length > 0 ? (
            sortedGames.map((row, index) => {
              const gameData = gameDataMap[row.gameData] || {};
              const tp = row.tp;

              const metrics = calculateMetrics(row, selectedLeague);

              const vegasOddsValue = row.vegasOdds?.pickExtraInnings || "0";
              const ml = parseFloat(
                row.result?.vegasOdds?.pickingFavorite ||
                  row.result?.vegasOdds?.pickingUnderdog ||
                  0
              ).toFixed(2);
              // Check if the values match
              const isPredictionCorrect =
                (row.pick_visitor || "-") === (gameData.vFinalScore || "-") &&
                (row.pick_home || "-") === (gameData.hFinalScore || "-");

              // Set the color based on the prediction
              const predictionColortext = isPredictionCorrect
                ? "green"
                : "initial";
              const predictionColor = isPredictionCorrect ? "#dba418" : "white";

              // Check if the final score is available
              const isFinalScoreAvailable =
                gameData.vFinalScore !== undefined &&
                gameData.hFinalScore !== undefined;

              // Determine if visitor or home team won based on the final score
              const isVisitorWinnerFinal =
                isFinalScoreAvailable &&
                gameData.vFinalScore > gameData.hFinalScore;

              const isHomeWinnerFinal =
                isFinalScoreAvailable &&
                gameData.hFinalScore > gameData.vFinalScore;

              // Check if the predicted score is available
              const isPredictionAvailable =
                row.pick_visitor !== undefined && row.pick_home !== undefined;

              // Determine if visitor or home team won based on the predicted score
              const isVisitorWinnerPrediction =
                isPredictionAvailable && row.pick_visitor > row.pick_home;
              const isHomeWinnerPrediction =
                isPredictionAvailable && row.pick_home > row.pick_visitor;
              const ou = parseFloat(
                row.result?.vegasOdds?.pickingOver ||
                  row.result?.vegasOdds?.pickingUnder ||
                  0
              ).toFixed(2);

              const spread = parseFloat(
                row.result?.vegasOdds?.pickingSpread?.vSpreadPoints ||
                  row.result?.vegasOdds?.pickingSpread?.hSpreadPoints ||
                  0
              ).toFixed(2);

              return (
                <tr
                  key={index}
                  className="h-14 bg-[#181818] text-white row-slide-in"
                  style={{
                    marginTop: "-30px",
                    animationDelay: `${index * 0.05}s`,
                  }}
                >
                  <td
                    className="text-xs font-medium text-center"
                    style={{
                      backgroundColor: isVisitorWinnerFinal
                        ? "green"
                        : "initial",
                      // color: isVisitorWinnerFinal ? "#dba418" : "white",
                      fontSize: isVisitorWinnerFinal ? "16px" : "",
                      textShadow: isVisitorWinnerFinal
                        ? "2px 2px 3px black"
                        : "none",

                      border: "2px solid black",
                    }}
                  >
                    {headerOptions[gameData?.visitor] || gameData?.visitor}
                  </td>
                  <td
                    className="text-xs font-medium text-center"
                    style={{
                      backgroundColor: isHomeWinnerFinal ? "green" : "initial",
                      // color: isHomeWinnerFinal ? "#dba418" : "white",
                      border: "2px solid black",

                      fontSize: isHomeWinnerFinal ? "16px" : "",
                      textShadow: isHomeWinnerFinal
                        ? "2px 2px 3px black"
                        : "none",
                    }}
                  >
                    {headerOptions[gameData?.home] || gameData?.home}
                  </td>
                  <td
                    className="text-center"
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      // backgroundColor: "#078C03",
                      textShadow: "2px 2px 3px black",
                    }}
                  >
                    <span
                      style={
                        {
                          // color: isVisitorWinnerFinal ? "green" : "red",
                          // textUnderlineOffset : '2'
                        }
                      }
                    >
                      {gameData.vFinalScore || "-"}
                    </span>{" "}
                    -{" "}
                    <span
                      style={
                        {
                          // color: isHomeWinnerFinal ? "green" : "red",
                        }
                      }
                    >
                      {gameData.hFinalScore || "-"}
                    </span>
                  </td>
                  <td
                    className="text-center"
                    style={{
                      fontSize: "16px",
                      textShadow: "2px 2px 3px black",
                      fontWeight: "bold",
                      border: "2px solid black",
                      backgroundColor:
                        // Check if the user's predicted higher score matches the final result
                        (row.pick_visitor > row.pick_home &&
                          gameData.vFinalScore > gameData.hFinalScore) ||
                        (row.pick_home > row.pick_visitor &&
                          gameData.hFinalScore > gameData.vFinalScore)
                          ? "green" // Correct prediction, set background to green
                          : "transparent",
                      color:
                        // Check if the user's predicted higher score matches the final result
                        (row.pick_visitor > row.pick_home &&
                          gameData.vFinalScore > gameData.hFinalScore) ||
                        (row.pick_home > row.pick_visitor &&
                          gameData.hFinalScore > gameData.vFinalScore)
                          ? "#dba418" // Correct prediction, set background to green
                          : "white", // Otherwise, no background change
                    }}
                  >
                    {row.pick_visitor || "-"} - {row.pick_home || "-"}
                  </td>
                  <td
                    className="text-xs font-medium text-center"
                    style={{ color: "#fbfbd4" }}
                  >
                    {new Date(row.createdAt).toLocaleTimeString()}
                  </td>
                  <td
                    className="text-xs font-medium text-center"
                    style={{ color: "#fbfbd4" }}
                  >
                    {row.co || "-"}
                  </td>
                  {/* <td
                    className="text-xs font-medium text-center"
                    style={{ color: "#fbfbd4" }}
                  >
  {TimeFormat(gameData?.time)}
                    
                  </td> */}

                  <td
                    className="text-xs font-medium text-center"
                    style={{ color: "#fbfbd4" }}
                  >
                    {row.state || "-"}
                  </td>
                  <td
                    className="text-xs font-medium text-center"
                    style={{ color: "#fbfbd4" }}
                  >
                    {row.city || "-"}
                  </td>
                  <td
                    className="text-xs font-medium text-center"
                    style={{ color: "#dba418", fontSize: "17px" }}
                  >
                    {row.player || "-"}
                  </td>
                  <td
                    className="text-xs font-medium text-center"
                    style={
                      {
                        // border: "2px solid green",
                      }
                    }
                  >
                    {ranks[index] || "-"}
                  </td>
                  <td
                    className="text-xs font-medium text-center"
                    style={{
                      border: "2px solid black",
                      color: parseFloat(tp) === 0 ? "red" : "white", // Text color logic
                      backgroundColor:
                        parseFloat(tp) !== 0 ? "green" : "transparent", // No background for icon, gold for value
                      textShadow:
                        parseFloat(tp) !== 0 ? "2px 2px 3px black" : "none", // Text shadow for non-0.00
                    }}
                  >
                    {parseFloat(tp) === 0 ? (
                      <CloseIcon style={{ color: "red", fontSize: "2.5em" }} />
                    ) : (
                      tp || "-"
                    )}
                  </td>
                  <td
                    className="text-xs font-medium text-center"
                    style={{
                      border: "2px solid black",
                      color: parseFloat(row.BR) === 0 ? "red" : "white", // Text color logic
                      backgroundColor: "transparent", // Always transparent for consistent styling
                      position: "relative", // Enable positioning for child elements
                      textShadow:
                        parseFloat(row.BR) !== 0 ? "2px 2px 3px black" : "none", // Text shadow for non-zero value
                      padding: "10px", // Add padding if needed
                      height: "60px", // Set a fixed height for the cell
                    }}
                  >
                    {/* Background image */}
                    {parseFloat(row.BR) !== 0 && (
                      <div
                        style={{
                          backgroundImage: `url(${UBRbg})`,
                          backgroundSize: "40px 30px",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "top center", // Align background image to the top center
                          position: "absolute", // Position the background image absolutely
                          top: 7, // Align it to the top
                          left: "50%", // Center it horizontally
                          transform: "translateX(-50%)", // Center it properly
                          height: "30px", // Set a height for the background image
                          width: "40px", // Set a width for the background image
                        }}
                      />
                    )}

                    <div
                      style={{
                        position: "absolute", // Position text absolutely
                        bottom: "4px", // Align to the bottom with some margin
                        left: "50%", // Center it horizontally
                        transform: "translateX(-50%)", // Center it properly
                        color: "gold",
                      }}
                    >
                      {parseFloat(row.BR) === 0 ? (
                        <CloseIcon
                          style={{ color: "red", fontSize: "2.5em" , marginTop: '-35px' }}
                        />
                      ) : (
                        row.BR || "-"
                      )}
                    </div>
                  </td>
                  {/* <td
                    className="text-xs font-medium text-center"
                    style={{
                      border: "2px solid black",
                      color: parseFloat(row.BR) === 0 ? "red" : "white", // Text color logic
                      backgroundColor: "transparent", // Always transparent for consistent styling
                      position: "relative", // Enable positioning for child elements
                      textShadow:
                        parseFloat(row.BR) !== 0 ? "2px 2px 3px black" : "none", // Text shadow for non-zero value
                      padding: "10px", // Add padding if needed
                      height: "60px", // Set a fixed height for the cell
                    }}
                  >
                    {row.BR}
                  </td> */}

                  <td
                    className="text-xs font-medium text-center"
                    style={{
                      border: "2px solid black",
                      color:
                        (row.pick_visitor > row.pick_home &&
                          gameData.vFinalScore > gameData.hFinalScore) ||
                        (row.pick_home > row.pick_visitor &&
                          gameData.hFinalScore > gameData.vFinalScore)
                          ? "white" // Show white text when the prediction is correct
                          : "red", // Otherwise, show red text
                      backgroundColor:
                        (row.pick_visitor > row.pick_home &&
                          gameData.vFinalScore > gameData.hFinalScore) ||
                        (row.pick_home > row.pick_visitor &&
                          gameData.hFinalScore > gameData.vFinalScore)
                          ? "green" // Set background green when prediction is correct
                          : "transparent", // Otherwise, no background
                      textShadow:
                        (row.pick_visitor > row.pick_home &&
                          gameData.vFinalScore > gameData.hFinalScore) ||
                        (row.pick_home > row.pick_visitor &&
                          gameData.hFinalScore > gameData.vFinalScore)
                          ? "2px 2px 3px black" // Apply text shadow when the prediction is correct
                          : "none", // No text shadow otherwise
                    }}
                  >
                    {
                      // Check if the user's prediction is correct before showing ml
                      (row.pick_visitor > row.pick_home &&
                        gameData.vFinalScore > gameData.hFinalScore) ||
                      (row.pick_home > row.pick_visitor &&
                        gameData.hFinalScore > gameData.vFinalScore) ? (
                        ml || "-" // Show ml value if the prediction is correct
                      ) : (
                        <CloseIcon
                          style={{ color: "red", fontSize: "2.5em" }}
                        /> // Otherwise show the CloseIcon
                      )
                    }
                  </td>

                  <td
                    className="text-xs font-medium text-center"
                    style={{
                      border: "2px solid black",
                      color: parseFloat(spread) === 0 ? "red" : "white", // Text color logic
                      backgroundColor:
                        parseFloat(spread) !== 0 ? "green" : "transparent", // No background for icon, gold for value
                      textShadow:
                        parseFloat(spread) !== 0 ? "2px 2px 3px black" : "none", // Text shadow for non-0.00
                    }}
                  >
                    {parseFloat(spread) === 0 ? (
                      <CloseIcon style={{ color: "red", fontSize: "2.5em" }} />
                    ) : (
                      spread || "-"
                    )}
                  </td>
                  <td
                    className="text-xs font-medium text-center"
                    style={{
                      border: "2px solid black",
                      color: parseFloat(metrics.oneS) === 0 ? "red" : "white",
                      backgroundColor:
                        parseFloat(metrics.oneS) !== 0
                          ? "green"
                          : "transparent",
                      textShadow:
                        parseFloat(metrics.oneS) !== 0
                          ? "2px 2px 3px black"
                          : "none",
                    }}
                  >
                    {parseFloat(metrics.oneS) === 0 ? (
                      <CloseIcon style={{ color: "red", fontSize: "2.5em" }} />
                    ) : (
                      metrics.oneS || "-"
                    )}
                  </td>
                  <td
                    className="text-xs font-medium text-center"
                    style={{
                      border: "2px solid black",
                      color: parseFloat(metrics.oneSO) === 0 ? "red" : "white",
                      backgroundColor:
                        parseFloat(metrics.oneSO) !== 0
                          ? "green"
                          : "transparent",
                      textShadow:
                        parseFloat(metrics.oneSO) !== 0
                          ? "2px 2px 3px black"
                          : "none",
                    }}
                  >
                    {parseFloat(metrics.oneSO) === 0 ? (
                      <CloseIcon style={{ color: "red", fontSize: "2.5em" }} />
                    ) : (
                      metrics.oneSO || "-"
                    )}
                  </td>

                  {/* Only render 1S and 1S0 for NHL */}
                  {selectedLeague === "NHL" && (
                    <>
                      <td
                        className="text-xs font-medium text-center"
                        style={{
                          border: "2px solid black",
                          color: parseFloat(ou) === 0 ? "red" : "white", // Text color logic
                          backgroundColor:
                            parseFloat(ou) !== 0 ? "green" : "transparent", // No background for icon, gold for value
                          textShadow:
                            parseFloat(ou) !== 0 ? "2px 2px 3px black" : "none", // Text shadow for non-0.00
                        }}
                      >
                        {parseFloat(ou) === 0 ? (
                          <CloseIcon
                            style={{ color: "red", fontSize: "2.5em" }}
                          />
                        ) : (
                          ou || "-"
                        )}
                      </td>
                    </>
                  )}
                  {selectedLeague === "NFL" && (
                    <>
                      <td
                        className="text-xs font-medium text-center"
                        style={{
                          border: "2px solid black",
                          color: parseFloat(ou) === 0 ? "red" : "white",
                          backgroundColor:
                            parseFloat(ou) !== 0 ? "green" : "transparent",
                          textShadow:
                            parseFloat(ou) !== 0 ? "2px 2px 3px black" : "none",
                        }}
                      >
                        {parseFloat(ou) === 0 ? (
                          <CloseIcon
                            style={{ color: "red", fontSize: "2.5em" }}
                          />
                        ) : (
                          ou || "-"
                        )}
                      </td>
                      <td
                        className="text-xs font-medium text-center"
                        style={{
                          border: "2px solid black",
                          color: parseFloat(ou) === 0 ? "red" : "white",
                          backgroundColor:
                            parseFloat(ou) !== 0 ? "green" : "transparent",
                          textShadow:
                            parseFloat(ou) !== 0 ? "2px 2px 3px black" : "none",
                        }}
                      >
                        {parseFloat(ou) === 0 ? (
                          <CloseIcon
                            style={{ color: "red", fontSize: "2.5em" }}
                          />
                        ) : (
                          "2S0" || "-"
                        )}
                      </td>
                      <td
                        className="text-xs font-medium text-center"
                        style={{
                          border: "2px solid black",
                          color:
                            parseFloat(metrics.oneSW3) === 0 ? "red" : "white",
                          backgroundColor:
                            parseFloat(metrics.oneSW3) !== 0
                              ? "green"
                              : "transparent",
                          textShadow:
                            parseFloat(metrics.oneSW3) !== 0
                              ? "2px 2px 3px black"
                              : "none",
                        }}
                      >
                        {parseFloat(metrics.oneSW3) === 0 ? (
                          <CloseIcon
                            style={{ color: "red", fontSize: "2.5em" }}
                          />
                        ) : (
                          metrics.oneSW3 || "-"
                        )}
                      </td>
                      <td
                        className="text-xs font-medium text-center"
                        style={{
                          border: "2px solid black",
                          color:
                            parseFloat(metrics.twoSW3) === 0 ? "red" : "white",
                          backgroundColor:
                            parseFloat(metrics.twoSW3) !== 0
                              ? "green"
                              : "transparent",
                          textShadow:
                            parseFloat(metrics.twoSW3) !== 0
                              ? "2px 2px 3px black"
                              : "none",
                        }}
                      >
                        {parseFloat(metrics.twoSW3) === 0 ? (
                          <CloseIcon
                            style={{ color: "red", fontSize: "2.5em" }}
                          />
                        ) : (
                          metrics.twoSW3 || "-"
                        )}
                      </td>
                      <td
                        className="text-xs font-medium text-center"
                        style={{
                          border: "2px solid black",
                          color:
                            parseFloat(metrics.oneSW7) === 0 ? "red" : "white",
                          backgroundColor:
                            parseFloat(metrics.oneSW7) !== 0
                              ? "green"
                              : "transparent",
                          textShadow:
                            parseFloat(metrics.oneSW7) !== 0
                              ? "2px 2px 3px black"
                              : "none",
                        }}
                      >
                        {parseFloat(metrics.oneSW7) === 0 ? (
                          <CloseIcon
                            style={{ color: "red", fontSize: "2.5em" }}
                          />
                        ) : (
                          metrics.oneSW7 || "-"
                        )}
                      </td>
                      <td
                        className="text-xs font-medium text-center"
                        style={{
                          border: "2px solid black",
                          color:
                            parseFloat(metrics.twoSW7) === 0 ? "red" : "white",
                          backgroundColor:
                            parseFloat(metrics.twoSW7) !== 0
                              ? "green"
                              : "transparent",
                          textShadow:
                            parseFloat(metrics.twoSW7) !== 0
                              ? "2px 2px 3px black"
                              : "none",
                        }}
                      >
                        {parseFloat(metrics.twoSW7) === 0 ? (
                          <CloseIcon
                            style={{ color: "red", fontSize: "2.5em" }}
                          />
                        ) : (
                          metrics.twoSW7 || "-"
                        )}
                      </td>
                    </>
                  )}
                  {selectedLeague === "NBA" && (
                    <>
                      <td
                        className="text-xs font-medium text-center"
                        style={{
                          border: "2px solid black",
                          color:
                            parseFloat(metrics.oneSW3) === 0 ? "red" : "white",
                          backgroundColor:
                            parseFloat(metrics.oneSW3) !== 0
                              ? "green"
                              : "transparent",
                          textShadow:
                            parseFloat(metrics.oneSW3) !== 0
                              ? "2px 2px 3px black"
                              : "none",
                        }}
                      >
                        {parseFloat(metrics.oneSW3) === 0 ? (
                          <CloseIcon
                            style={{ color: "red", fontSize: "2.5em" }}
                          />
                        ) : (
                          metrics.oneSW3 || "-"
                        )}
                      </td>
                      <td
                        className="text-xs font-medium text-center"
                        style={{
                          border: "2px solid black",
                          color:
                            parseFloat(metrics.twoSW3) === 0 ? "red" : "white",
                          backgroundColor:
                            parseFloat(metrics.twoSW3) !== 0
                              ? "green"
                              : "transparent",
                          textShadow:
                            parseFloat(metrics.twoSW3) !== 0
                              ? "2px 2px 3px black"
                              : "none",
                        }}
                      >
                        {parseFloat(metrics.twoSW3) === 0 ? (
                          <CloseIcon
                            style={{ color: "red", fontSize: "2.5em" }}
                          />
                        ) : (
                          metrics.twoSW3 || "-"
                        )}
                      </td>
                      <td
                        className="text-xs font-medium text-center"
                        style={{
                          border: "2px solid black",
                          color:
                            parseFloat(metrics.oneSW7) === 0 ? "red" : "white",
                          backgroundColor:
                            parseFloat(metrics.oneSW7) !== 0
                              ? "green"
                              : "transparent",
                          textShadow:
                            parseFloat(metrics.oneSW7) !== 0
                              ? "2px 2px 3px black"
                              : "none",
                        }}
                      >
                        {parseFloat(metrics.oneSW7) === 0 ? (
                          <CloseIcon
                            style={{ color: "red", fontSize: "2.5em" }}
                          />
                        ) : (
                          metrics.oneSW7 || "-"
                        )}
                      </td>
                      <td
                        className="text-xs font-medium text-center"
                        style={{
                          border: "2px solid black",
                          color:
                            parseFloat(metrics.twoSW7) === 0 ? "red" : "white",
                          backgroundColor:
                            parseFloat(metrics.twoSW7) !== 0
                              ? "green"
                              : "transparent",
                          textShadow:
                            parseFloat(metrics.twoSW7) !== 0
                              ? "2px 2px 3px black"
                              : "none",
                        }}
                      >
                        {parseFloat(metrics.twoSW7) === 0 ? (
                          <CloseIcon
                            style={{ color: "red", fontSize: "2.5em" }}
                          />
                        ) : (
                          metrics.twoSW7 || "-"
                        )}
                      </td>
                    </>
                  )}
                  {selectedLeague === "MLB" && (
                    <>
                      <td
                        className="text-xs font-medium text-center"
                        style={{
                          border: "2px solid black",
                          color: parseFloat(ou) === 0 ? "red" : "white",
                          backgroundColor:
                            parseFloat(ou) !== 0 ? "green" : "transparent",
                          textShadow:
                            parseFloat(ou) !== 0 ? "2px 2px 3px black" : "none",
                        }}
                      >
                        {parseFloat(ou) === 0 ? (
                          <CloseIcon
                            style={{ color: "red", fontSize: "2.5em" }}
                          />
                        ) : (
                          ou || "-"
                        )}
                      </td>
                      <td
                        className="text-xs font-medium text-center"
                        style={{
                          border: "2px solid black",
                          color:
                            parseFloat(metrics.oneSW3) === 0 ? "red" : "white",
                          backgroundColor:
                            parseFloat(metrics.oneSW3) !== 0
                              ? "green"
                              : "transparent",
                          textShadow:
                            parseFloat(metrics.oneSW3) !== 0
                              ? "2px 2px 3px black"
                              : "none",
                        }}
                      >
                        {parseFloat(metrics.oneSW3) === 0 ? (
                          <CloseIcon
                            style={{ color: "red", fontSize: "2.5em" }}
                          />
                        ) : (
                          metrics.oneSW2 || "-"
                        )}
                      </td>
                      <td
                        className="text-xs font-medium text-center"
                        style={{
                          border: "2px solid black",
                          color:
                            parseFloat(metrics.twoSW3) === 0 ? "red" : "white",
                          backgroundColor:
                            parseFloat(metrics.twoSW3) !== 0
                              ? "green"
                              : "transparent",
                          textShadow:
                            parseFloat(metrics.twoSW3) !== 0
                              ? "2px 2px 3px black"
                              : "none",
                        }}
                      >
                        {parseFloat(metrics.twoSW3) === 0 ? (
                          <CloseIcon
                            style={{ color: "red", fontSize: "2.5em" }}
                          />
                        ) : (
                          metrics.twoSW2 || "-"
                        )}
                      </td>
                    </>
                  )}
                  {/* Render Reg, OT, SO columns */}
                  {renderColumns(row)}
                </tr>
              );
            })
          ) : (
            <tr>
              <td
                colSpan={filteredHeaderOptions.length}
                className="text-xs font-medium text-center"
              >
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableComponent;
