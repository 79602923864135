import api from "./api";

export const addPrediction = async (data) => {
  const response = await api.post(
    "api/user/gamesplayed/create",
    JSON.stringify(data)
  );
  return response;
};

export const getGamePlayedByUserId = async (userId) => {
  const response = await api.get(`api/user/gamesplayed/gamePlayed/${userId}`);
  return response;
};

export const getGamesPlayedByDate = async (date) => {
  const response = await api.get(
    `api/user/gamesplayed/games-played-by-date/${date}`
  );
  return response;
};

export const getGamesPlayedByDateRange = async (startDate, endDate) => {
  const response = await api.get(
    `api/user/gamesplayed/games-played-by-date-range/${startDate}/${endDate}`
  );
  return response;
};

export const getPredictionByGameAndUser = async (gameId, userId) => {
  const response = await api.get(
    `api/user/gamesplayed/prediction/${gameId}/${userId}`
  );
  return response.data;
};
