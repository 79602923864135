import React, { useEffect, useState } from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";

import HeroSection from "../components/HeroSection.js";
import MainNavBar from "../components/MainNavBar";
import Line from "../components/Line.js";
import Banner from "../components/Banner.js";
import GameCard from "../components/GameCard/GameCard.js";
import img1 from "../assets/Bus-PersonalBusinessCard-BusCard.png";
import img2 from "../assets/BR-BusinessCardV2 copy.png";
import GamerCardRight from "../components/GameCard/GamerCardRight/GamerCardRight.js";
import GamercardR from "../components/GameCard/GamecardR.js";
import { getGames, fetchGamesForNext10Days } from "../Apis/games.js";
import { useQuery } from "react-query";
import { format, add } from "date-fns";
import { useLeagueContext } from "../components/LeagueContext.js";
import imgBasketball1 from "../assets/Basketball-1.png";
import imgBasketball5 from "../assets/Basketball-5.png";
import imgFootball1 from "../assets/Football-1.png";
import imgFootball6 from "../assets/Football-6.png";
import imgHosckey1 from "../assets/Hockey-1.png";
import imgHosckey4 from "../assets/Hockey-4.png";
import banner from "../assets/FreeYearBanner.gif";

const images = [
  imgBasketball1,
  imgBasketball5,
  imgFootball1,
  imgFootball6,
  imgHosckey1,
  imgHosckey4,
];

const getRandomImage = () => {
  const randomIndex = Math.floor(Math.random() * images.length);
  return images[randomIndex];
};

const Games = () => {
  const gridStyles = {
    display: "grid",
    gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
    // gap: "1rem", // You can adjust the gap value accordingly
  };
  const [heroImg, setHeroImg] = useState(getRandomImage());
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const isAdmin = true; // Set this value based on whether the user is an admin or not
  const { selectedLeague } = useLeagueContext();
  const [gameData, setGameData] = useState([]);
  const [openRow, setOpenRow] = useState(null);
  const [tomorrowGameData, setTomorrowGameData] = useState([]); // Store tomorrow's games separately

  const date = new Date();
  const tomorrow = add(date, { days: 1 });
  const formattedDateForAPI = format(date, "MM-dd-yyyy");

  const getNFLWeekRange = () => {
    const today = new Date();
    const currentDay = today.getDay(); // 0 = Sunday, 1 = Monday, 2 = Tuesday, etc.
    
    // Calculate days to previous Tuesday
    const daysToTuesday = currentDay >= 2 ? -(currentDay - 2) : -(currentDay + 5);
    
    const startDate = new Date(today);
    startDate.setDate(today.getDate() + daysToTuesday);
    
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 6); // 6 days after Tuesday = Monday
    
    return {
      start: format(startDate, "MM-dd-yyyy"),
      end: format(endDate, "MM-dd-yyyy")
    };
  };

  const {
    isLoading: loadingTeams,
    isError: teamError,
    refetch: refetchGames,
  } = useQuery(["teams", formattedDateForAPI, selectedLeague], async () => {
    if (selectedLeague === "NFL") {
      // Use fetchGamesForNext10Days for NFL
      const allNFLGames = await fetchGamesForNext10Days("NFL");
      
      // Filter games for current NFL week
      const weekRange = getNFLWeekRange();
      const weeklyGames = allNFLGames.filter(game => {
        const gameDate = new Date(game.gamedate);
        const gameDateStr = format(gameDate, "MM-dd-yyyy");
        return (
          game.league === "NFL" &&
          gameDateStr >= weekRange.start &&
          gameDateStr <= weekRange.end
        );
      });
      
      return { data: weeklyGames };
    } else {
      // For other leagues, use the existing getGames function
      return getGames({ queryKey: ["teams", formattedDateForAPI, selectedLeague] });
    }
  }, {
    onSuccess: (fetchedData) => {
      if (selectedLeague === "NHL") {
        // NHL logic remains the same
        const filteredGames = fetchedData.data.filter(game => {
          const gameDate = new Date(game.gamedate);
          const gameDateStr = format(gameDate, "MM-dd-yyyy");
          return (
            game.league === selectedLeague &&
            (gameDateStr === formattedDateForAPI ||
             gameDateStr === format(tomorrow, "MM-dd-yyyy"))
          );
        });
        setGameData(filteredGames);
      } else {
        // For NFL and other leagues, use data directly
        setGameData(fetchedData.data);
      }
    },
    enabled: false,
    onError: (error) => {
      console.error("An error occurred:", error);
    },
  });

  useEffect(() => {
    refetchGames();
  }, [selectedLeague]);

  const handleToggle = (index) => {
    setOpenRow(openRow === index ? null : index);
  };

  // Add new state for image carousel
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  // Define all 6 images
  const carouselImages = [img1, img2, img1, img2, img1, img2]; // Added more images

  // Navigation functions
  const nextSlide = () => {
    setCurrentImageIndex((prevIndex) => 
      prevIndex === carouselImages.length - 3 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentImageIndex((prevIndex) => 
      prevIndex === 0 ? carouselImages.length - 3 : prevIndex - 1
    );
  };

  // Auto scroll effect with pause capability
  useEffect(() => {
    if (!isPaused) {
      const interval = setInterval(() => {
        nextSlide();
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [isPaused]);

  // Add this new function to group games by day
  const groupGamesByDay = (games) => {
    const grouped = games.reduce((acc, game) => {
      const gameDate = new Date(game.gamedate);
      const dayKey = format(gameDate, 'EEEE, MMMM d'); // Format: "Monday, January 15"
      
      if (!acc[dayKey]) {
        acc[dayKey] = [];
      }
      acc[dayKey].push(game);
      return acc;
    }, {});

    // Sort days chronologically
    return Object.entries(grouped).sort(([dateA], [dateB]) => {
      return new Date(dateA) - new Date(dateB);
    });
  };

  return (
    <Grid container spacing={4} direction="column">
      <img src={banner} alt="img1" className="w-full" />
      {/* <Grid item>
        <HeroSection imgUrl={heroImg} />
      </Grid>
      <Grid item>
        <MainNavBar />
      </Grid> */}
      <Grid item>
        <Line />
      </Grid>
      {/* <Grid item>
        <Banner date={formattedDate} label="Upcoming Games" />
      </Grid> */}
      {selectedLeague === "NFL" ? (
        // First check if there are any games at all
        gameData && gameData.length > 0 ? (
          groupGamesByDay(gameData).map(([day, gamesForDay]) => (
            <React.Fragment key={day}>
              <Grid item>
                <Typography 
                  variant="h5" 
                  sx={{
                    color: 'white',
                    padding: '10px',
                    backgroundColor: '#1a1a1a',
                    marginBottom: '-30px',
                    marginTop: '0px',
                    borderRadius: '5px',
                    width: isMobile ? "98%" : "107%",
                    marginLeft: isMobile ? "-10px" : "-120px",
                  }}
                >
                  {day}
                </Typography>
              </Grid>
              <Grid
                className="game-grid"
                container
                item
                spacing={2}
                justifyContent="flex-start"
                sx={{
                  width: isMobile ? "98%" : "115%",
                  marginLeft: isMobile ? "-10px" : "-130px",
                }}
              >
                {gamesForDay.map((game, index) => (
                  <Grid key={game.id} item xs={12} md={4}>
                    {index % 2 === 0 ? (
                      <GameCard
                        gameData={game}
                        isOpen={openRow === index}
                        onToggle={() => handleToggle(index)}
                        isAdmin={isAdmin}
                      />
                    ) : (
                      <GamercardR
                        gameData={game}
                        isOpen={openRow === index}
                        onToggle={() => handleToggle(index)}
                        isAdmin={isAdmin}
                      />
                    )}
                  </Grid>
                ))}
              </Grid>
            </React.Fragment>
          ))
        ) : (
          // No games available message for NFL
          <Grid item>
            <Typography
              variant="h5"
              color="textSecondary"
              style={{ 
                color: "white", 
                margin: "auto",
                textAlign: "center",
                width: "100%",
                // padding: "20px"
              }}
            >
              No Games available.
            </Typography>
          </Grid>
        )
      ) : (
        // Original layout for other leagues
        <Grid
          className="game-grid"
          container
          item
          spacing={2}
          justifyContent="flex-start"
          sx={{
            width: isMobile ? "98%" : "115%",
            marginLeft: isMobile ? "-10px" : "-120px",
          }}
        >
          {gameData && gameData.length > 0 ? (
            gameData.map((game, index) => (
              <Grid key={game.id} item xs={12} md={4}>
                {index % 2 === 0 ? (
                  <GameCard
                    gameData={game}
                    isOpen={openRow === index}
                    onToggle={() => handleToggle(index)}
                    isAdmin={isAdmin}
                  />
                ) : (
                  <GamercardR
                    gameData={game}
                    isOpen={openRow === index}
                    onToggle={() => handleToggle(index)}
                    isAdmin={isAdmin}
                  />
                )}
              </Grid>
            ))
          ) : (
            <Typography
              variant="h5"
              color="textSecondary"
              style={{ color: "white", margin: "auto" }}
            >
              No Games available.
            </Typography>
          )}
        </Grid>
      )}

      <Grid item>
        <Line />
      </Grid>
      <Grid 
        sx={{
          width: isMobile ? "98%" : "115%",
          border: "hidden",
          marginBottom: "0%",
          marginTop: "0%",
          marginLeft: isMobile ? "-10px" : "-120px",
          overflow: "hidden",
          position: "relative",
        }}
        container 
        item 
        spacing={1} 
        justifyContent="flex-start"
      >
        {/* Navigation Buttons */}
        <button
          onClick={prevSlide}
          onMouseEnter={() => setIsPaused(true)}
          onMouseLeave={() => setIsPaused(false)}
          style={{
            position: "absolute",
            left: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 3,
            background: "rgba(0,0,0,0.5)",
            color: "white",
            border: "none",
            borderRadius: "50%",
            width: "40px",
            height: "40px",
            cursor: "pointer"
          }}
        >
          ←
        </button>
        <button
          onClick={nextSlide}
          onMouseEnter={() => setIsPaused(true)}
          onMouseLeave={() => setIsPaused(false)}
          style={{
            position: "absolute",
            right: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 3,
            background: "rgba(0,0,0,0.5)",
            color: "white",
            border: "none",
            borderRadius: "50%",
            width: "40px",
            height: "40px",
            cursor: "pointer"
          }}
        >
          →
        </button>

        <div 
          style={{ 
            display: "flex",
            transition: "transform 0.5s ease",
            transform: `translateX(-${currentImageIndex * (100/3)}%)`,
            width: "100%"
          }}
          onMouseEnter={() => setIsPaused(true)}
          onMouseLeave={() => setIsPaused(false)}
        >
          {carouselImages.map((img, index) => (
            <Grid item xs={12} md={4} key={index} style={{ flex: "0 0 33.333%" }}>
              <img 
                style={{
                  width: "500px",
                  transition: "transform 0.5s ease"
                }} 
                src={img} 
                alt={`carousel-img-${index}`} 
                className="w-full" 
              />
            </Grid>
          ))}
        </div>
      </Grid>
      {/* <Grid item>
        <Banner date={nextFormattedDate} label="Tomorrow's Games" />
      </Grid> */}
      {/* <Grid
        className="game-grid"
        container
        item
        spacing={2}
        justifyContent="flex-start"
        sx={{
          width: isMobile ? "80%" : "110%",
          marginLeft: isMobile ? "20px" : "-60px",
        }}
      >
        {" "}
        {tomorrowGameData ? (
          tomorrowGameData.length > 0 ? (
            tomorrowGameData.map((game, index) => (
              <Grid key={game.id} item xs={12} md={6}>
                {index % 2 === 0 ? (
                  <GameCard gameData={game} isAdmin={isAdmin} />
                ) : (
                  <GamerCardRight gameData={game} />
                )}
              </Grid>
            ))
          ) : (
            <Typography variant="body1" color="textSecondary">
              No games available for tomorrow.
            </Typography>
          )
        ) : (
          <Typography variant="body1" color="textSecondary">
            Loading tomorrow's games...
          </Typography>
        )}
      </Grid> */}
      <Box m={3} />
    </Grid>
  );
};

export default Games;
