import React, { useState, useEffect } from "react";
import TimeFormat from "../../services/TimeFormat";
import { useMediaQuery } from "@material-ui/core";
import "./GameCard.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Collapse,
  IconButton,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import Switches from "../Switches.js";
import MobileSwitches from "../Switchmobile.js";
import Modal from "react-modal";
import { addPrediction } from "../../Apis/predictions";
import displayToast from "../Alert/Alert";
import { useMutation } from "react-query";
import { useLeagueContext } from "../LeagueContext";
import Swal from "sweetalert2";
import { teamNameMappings } from "./data";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';

const MobileCard = ({ gameData, isOpen, onToggle }) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [open, setOpen] = useState(false);

  const [Pick_Ei, setPick_Ei] = useState(false);

  const labelStyles = {
    borderBottom: "2px solid #BE8200",
    width: "60%",
    textAlign: "center",
  };

  const [pick_visitor, setPickVisitor] = useState("");
  const [pick_home, setPickHome] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editedGameData, setEditedGameData] = useState({ ...gameData });
  const [Pick_Reg, setPick_Reg] = useState(false);
  const [Pick_ot, setPick_ot] = useState(false);
  const [Pick_so, setPick_so] = useState(false);
  const [Pick_num_ot, setPick_num_ot] = useState("");
  const [Pick_num_ei, setPick_num_ei] = useState("");
  const [Pick_num_so, setPick_num_so] = useState("");
  const [inputChanged, setInputChanged] = useState(false);
  const [invalidFields, setInvalidFields] = useState([]);
  const [isLockedIn, setIsLockedIn] = useState(() => {
    const lockedStatus = localStorage.getItem(`isLockedIn_${gameData._id}`);
    return lockedStatus === "true";
  });

  const [previousValues, setPreviousValues] = useState({
    pick_visitor: "",
    pick_home: "",
  });

  const cardId = gameData._id;

  const { selectedLeague } = useLeagueContext();

  const userId = localStorage.getItem("_id");

  const [gameEnding, setGameEnding] = useState(""); // State for gameEnding

  const [buttonText, setButtonText] = useState(
    <>
      <LockOpenIcon /> LOCK IT IN
    </>
  );

  const [enterPickText, setEnterPickText] = useState(
    <>
      <EditIcon /> ENTER PICK
    </>
  );

  const [buttonStyle, setButtonStyle] = useState({
    backgroundColor: "initial",
    color: "black",
  });

  const isGameStarted = () => {
    try {
      // Parse game time (e.g., "1:00 PM")
      const [timePart, meridiem] = gameData.time.split(' ');
      const [hours, minutes] = timePart.split(':');
      
      // Create date object for game time
      const gameDate = new Date(gameData.gamedate);
      let gameHours = parseInt(hours);
      
      // Convert to 24-hour format
      if (meridiem === 'PM' && gameHours !== 12) {
        gameHours += 12;
      } else if (meridiem === 'AM' && gameHours === 12) {
        gameHours = 0;
      }
      
      gameDate.setHours(gameHours, parseInt(minutes), 0);
      
      const currentTime = new Date();
      console.log('Game time:', gameDate);
      console.log('Current time:', currentTime);
      
      return currentTime >= gameDate;
    } catch (error) {
      console.error('Error parsing game time:', error);
      return false;
    }
  };

  useEffect(() => {
    const checkGameStatus = () => {
      const lockedStatus = localStorage.getItem(`isLockedIn_${gameData._id}`);
      
      if (isGameStarted()) {
        // Game has started
        setIsLockedIn(true);
        setButtonText(<><LockIcon /> GAME STARTED</>);
        setEnterPickText(<>GAME STARTED</>);
        setButtonStyle({ backgroundColor: "initial", color: "red" });
        
        // Disable all inputs in this card
        const cardElement = document.querySelector(`#game-card-${gameData._id}`);
        if (cardElement) {
          const inputs = cardElement.querySelectorAll('input');
          inputs.forEach(input => {
            input.disabled = true;
          });
        }
        
        // Send prediction to backend if not already locked
        if (!lockedStatus) {
          sendPredictionToBackend();
          localStorage.setItem(`isLockedIn_${gameData._id}`, "true");
        }
      } else if (lockedStatus === "true") {
        // Game hasn't started but prediction is locked
        setIsLockedIn(true);
        setButtonText(<><LockIcon /> LOCKED</>);
        setEnterPickText(<>GAME PLAYED</>);
        setButtonStyle({ backgroundColor: "initial", color: "red" });
      } else {
        // Normal state - not started, not locked
        setButtonText(<><LockOpenIcon /> LOCK IT IN</>);
        setEnterPickText(
          <>
            <EditIcon /> ENTER PICK
          </>
        );
        setButtonStyle({ backgroundColor: "initial", color: "black" });
      }
    };

    // Check immediately
    checkGameStatus();

    // Check every second
    const interval = setInterval(checkGameStatus, 1000);

    return () => clearInterval(interval);
  }, [gameData._id, gameData.time, gameData.gamedate]);

  const getButtonStyle = () => {
    if (isGameStarted() && !isLockedIn) {
      return {
        backgroundColor: "#FF6B6B",  // Red background for game started
        color: "white",
        fontSize: isMobile ? "10px" : "11px",
      };
    }
    if (isLockedIn) {
      return {
        backgroundColor: "initial",  // No background for locked state
        color: "red",  // Red text color
        fontSize: isMobile ? "10px" : "11px",
      };
    }
    return {
      backgroundColor: "initial",
      color: "black",
      fontSize: isMobile ? "10px" : "11px",
    };
  };

  const handleEnterPick = () => {
    if (isGameStarted()) {
      displayToast(
        "The game has started. You can no longer edit your prediction.",
        "error"
      );
      return;
    }

    const invalidFields = [];
    if (!pick_visitor) invalidFields.push("pick_visitor");
    if (!pick_home) invalidFields.push("pick_home");
    if (!Pick_Reg && !Pick_ot && !Pick_so) invalidFields.push("pick_switch");

    setInvalidFields(invalidFields);

    if (invalidFields.length > 0) {
      Swal.fire({
        title: "Error",
        text: "Select one of the radio buttons.",
        icon: "error",
        background: "#212121",
        color: "white",
      });
      return;
    }

    // Save to localStorage
    const dataToSave = {
      gameData: gameData._id,
      pick_visitor,
      pick_home,
      gameEnding,
      userId,
      Pick_num_ot,
      Pick_num_so,
      Pick_num_ei,

      Pick_so,
      Pick_ot,
      Pick_Reg,
    };
    localStorage.setItem(gameData._id, JSON.stringify(dataToSave));
    displayToast("Prediction saved. Editable until game starts.", "success");
  };
  // console.log({
  //   "Game Data ID": gameData._id,
  // });
  const handleLockIn = () => {
    if (isGameStarted()) {
      displayToast(
        "The game has started. You can no longer edit your prediction.",
        "error"
      );
      return;
    }

    const invalidFields = [];
    const visitorScore = parseInt(pick_visitor);
    const homeScore = parseInt(pick_home);

    if (!pick_visitor || isNaN(visitorScore))
      invalidFields.push("pick_visitor");
    if (!pick_home || isNaN(homeScore)) invalidFields.push("pick_home");
    if (!Pick_Reg && !Pick_ot && !Pick_so && !Pick_Ei)
      invalidFields.push("pick_switch");

    setInvalidFields(invalidFields);

    if (invalidFields.length > 0) {
      Swal.fire({
        // title: "Error",
        text: "PLEASE SELECT HOW GAME WILL END",
        icon: "error",
        background: "#212121",
        color: "white",
      });
      return;
    }

    let showAlert = false;
    let alertMessage = "";
    let showError = false;
    let errorMessage = "";

    // Validation logic for specific leagues...
    // (NHL, NBA, MLB, NFL score checks)

    if (showError) {
      displayToast(errorMessage, "error");
    } else if (showAlert) {
      Swal.fire({
        title: "Are you sure?",
        text: alertMessage,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        background: "#212121",
        color: "white",
      }).then((result) => {
        if (result.isConfirmed) {
          lockInPrediction();
        }
      });
    } else {
      lockInPrediction();
    }
  };

  // Lock in prediction and prevent further edits
  const lockInPrediction = () => {
    const timestamp = new Date().toISOString();

    const dataToSave = {
      gameData: gameData._id,
      pick_visitor,
      pick_home,
      gameEnding,
      userId,
      Pick_num_ot,
      Pick_num_ei,
      Pick_num_so,
      Pick_so,
      Pick_ot,
      Pick_Reg,
      league: selectedLeague,
    };

    mutate(dataToSave); // Send to backend

    // Set the lock-in state to true so the warning doesn't appear
    setIsLockedIn(true);
    displayToast("Prediction locked.", "success");

    // Remove the local storage entry
    localStorage.removeItem(gameData._id);
  };
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!isLockedIn) {
        // Set a custom warning message
        const message =
          "You haven't locked in your prediction yet. Do you want to leave without locking it in?";
        event.returnValue = message; // For most modern browsers
        return message; // For older browsers
      }
    };

    // Attach event listener if the prediction is not locked
    if (!isLockedIn) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    // Cleanup the event listener
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isLockedIn]);

  // Call this function to send the locked-in prediction to the backend when game starts
  const sendPredictionToBackend = () => {
    if (isGameStarted()) {
      const dataFromLocalStorage = localStorage.getItem(gameData._id);
      if (dataFromLocalStorage) {
        const parsedData = JSON.parse(dataFromLocalStorage);
        mutate(parsedData);
        localStorage.removeItem(gameData._id);
        setIsLockedIn(true); // Lock the prediction
        displayToast("Prediction locked.", "success");
      }
    }
  };

  // Check if game has started and auto-lock the prediction
  useEffect(() => {
    if (isGameStarted()) {
      sendPredictionToBackend();
    }
  }, [gameData]);

  const { mutate, isLoading, isError, data, error, reset } = useMutation(
    (data) => addPrediction(data),
    {
      onSuccess: (data) => {
        displayToast("Prediction added successfully", "success");
      },
      onError: (error) => {
        displayToast("Error while adding the prediction", "error");
      },
    }
  );

  useEffect(() => {
    const date = new Date(gameData?.gamedate);
    const options = { month: "short", day: "numeric", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
  }, [gameData]);

  useEffect(() => {
    const storedVisitor = localStorage.getItem(`pick_visitor_${cardId}`);
    const storedHome = localStorage.getItem(`pick_home_${cardId}`);

    if (storedVisitor) {
      setPickVisitor(storedVisitor);
    }
    if (storedHome) {
      setPickHome(storedHome);
    }
  }, [cardId]);

  // Handle input change for visitor (allow only numbers)
  const handleInputChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      // Allows only numeric input
      if (value !== pick_visitor) {
        setInputChanged(true);
      }
      setPickVisitor(value);
      localStorage.setItem(`pick_visitor_${cardId}`, value); // Save to localStorage with unique key

      // Set expiration timestamp
      const expirationTime = new Date();
      expirationTime.setHours(2, 0, 0, 0); // 2 AM
      localStorage.setItem(
        `expiration_${cardId}`,
        expirationTime.toISOString()
      );
    }
  };

  // Handle input change for home (allow only numbers)
  const handleHomeChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      // Allows only numeric input
      if (value !== pick_home) {
        setInputChanged(true);
      }
      setPickHome(value);
      localStorage.setItem(`pick_home_${cardId}`, value); // Save to localStorage with unique key

      // Set expiration timestamp
      const expirationTime = new Date();
      expirationTime.setHours(2, 0, 0, 0); // 2 AM
      localStorage.setItem(
        `expiration_${cardId}`,
        expirationTime.toISOString()
      );
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (inputChanged) {
        const message =
          "You have unsaved changes, are you sure you want to leave?";
        e.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [inputChanged]);

  const handleSaveEdit = () => {
    setIsModalOpen(false);
    setInputChanged(false);
    setPreviousValues({ pick_visitor, pick_home });
    console.log("Saved data:", editedGameData);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const renderSwitches = (team) => (
    <Switches
      league={gameData?.league}
      season={gameData?.seasonflag}
      setPick_num_ot={setPick_num_ot}
      setPick_num_ei={setPick_num_ei}
      setPick_num_so={setPick_num_so}
      setPick_so={setPick_so}
      setPick_ot={setPick_ot}
      setPick_Reg={setPick_Reg}
      setPick_Ei={setPick_Ei}
      uniqueId={gameData._id}
      glowing={invalidFields.includes("pick_switch")}
      setGameEnding={setGameEnding} // Pass the function to update gameEnding
    />
  );

  const getScoreDifference = () => {
    const visitorScore = parseInt(pick_visitor) || 0;
    const homeScore = parseInt(pick_home) || 0;
    return Math.abs(visitorScore - homeScore);
  };

  return (
    <div id={`game-card-${gameData._id}`}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <TableCell
                sx={{
                  fontSize: "0.60rem",
                  textAlign: "left",
                  marginLeft: "10px",
                  padding: "0",
                  fontWeight: "bold",
                  lineHeight: "1.5",
                  position: "relative",
                  height: "80px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  flex: 1,
                }}
              >
                <label
                  className="visitor"
                  style={{
                    color: "#dba418",
                    // fontWeight: "bolder",
                  }}
                >
                  {teamNameMappings[gameData?.visitor] || gameData?.visitor}
                </label>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "100%",
                  }}
                >
                  <input
                    type="text"
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      width: "70px",
                      height: "30px",
                    }}
                    className={`score-input card-input m-1 ${
                      invalidFields.includes("pick_visitor")
                        ? "glowing-border"
                        : ""
                    }`}
                    value={pick_visitor}
                    onChange={(e) => setPickVisitor(e.target.value)}
                  />
                </div>
              </TableCell>

              <TableCell
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "80px",
                  flex: 1,
                }}
              >
                <label
                  style={{
                    color: "#dba418",
                    fontWeight: "bolder",
                  }}
                >
                  {TimeFormat(gameData?.time)}
                </label>
              </TableCell>

              <TableCell
                sx={{
                  fontSize: "0.60rem",
                  textAlign: "right",
                  marginRight: "10px",
                  padding: "0",
                  fontWeight: "bold",
                  lineHeight: "1.5",
                  position: "relative",
                  height: "80px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  flex: 1,
                }}
              >
                <label
                  className="visitor"
                  style={{
                    color: "#dba418",
                    // fontWeight: "bolder",
                  }}
                >
                  {teamNameMappings[gameData?.home] || gameData?.home}
                </label>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <input
                    type="text"
                    style={{
                      backgroundColor: "white",
                      width: "70px",
                      color: "black",
                      height: "30px",
                    }}
                    className={`score-input card-input m-1 ${
                      invalidFields.includes("pick_home")
                        ? "glowing-border"
                        : ""
                    }`}
                    value={pick_home}
                    onChange={(e) => setPickHome(e.target.value)}
                  />
                </div>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableHead>
            <TableRow
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <TableCell
                sx={{
                  fontSize: "0.60rem",
                  margin: "0",
                  padding: "0",
                  display: "flex",
                  flexDirection: "column",
                  border: "hidden",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flex: "1",
                  // height: "100px", // Adjust height as needed
                }}
              >
                <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                  {isMobile ? (
                    <MobileSwitches
                      league={gameData?.league}
                      season={gameData?.seasonflag}
                      setPick_num_ot={setPick_num_ot}
                      setPick_num_ei={setPick_num_ei}
                      setPick_num_so={setPick_num_so}
                      setPick_so={setPick_so}
                      setPick_ot={setPick_ot}
                      setPick_Reg={setPick_Reg}
                      setPick_Ei={setPick_Ei}
                      uniqueId={gameData._id}
                      glowing={invalidFields.includes("pick_switch")}
                      setGameEnding={setGameEnding}
                      disabled={selectedLeague === "NHL" && getScoreDifference() > 1}
                      pick_visitor={pick_visitor}
                      pick_home={pick_home}
                    />
                  ) : (
                    <Switches
                      league={gameData?.league}
                      season={gameData?.seasonflag}
                      setPick_num_ot={setPick_num_ot}
                      setPick_num_ei={setPick_num_ei}
                      setPick_num_so={setPick_num_so}
                      setPick_so={setPick_so}
                      setPick_ot={setPick_ot}
                      setPick_Reg={setPick_Reg}
                      setPick_Ei={setPick_Ei}
                      uniqueId={gameData._id}
                      glowing={invalidFields.includes("pick_switch")}
                      setGameEnding={setGameEnding}
                    />
                  )}
                </div>
              </TableCell>

              <TableCell
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flex: "1",
                  border: "hidden",
                }}
              >
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={onToggle}
                  sx={{
                    fontSize: "0.60rem",
                    color: "#dba418",
                    fontWeight: "bolder",
                  }}
                >
                  {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>
              </TableCell>

              <TableCell
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  flex: "1",
                  fontSize: "0.60rem",
                  border: "hidden",
                }}
              >
                <button
                  className="card-btn-enter mt-1"
                  style={{
                    fontSize: "8px",
                    color: "white",
                    padding: "2px 10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "6px",
                  }}
                  onClick={handleEnterPick}
                  disabled={isLockedIn || isGameStarted()}
                >
                  {enterPickText}
                </button>
                <button
                  className="card-btn mt-1"
                  style={{
                    fontSize: "8px",
                    padding: "2px 10px",
                    ...getButtonStyle(),
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "6px",
                  }}
                  onClick={handleLockIn}
                  disabled={isLockedIn || isGameStarted()}
                >
                  {buttonText}
                </button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{ fontSize: "0.60rem", textAlign: "center" }}
                        >
                          <label style={labelStyles}>
                            {gameData?.["v-ml"]}
                          </label>
                          <br />
                          <label>{gameData?.["v-ml-points"]} Pts</label>
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "0.60rem",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          <label>Money Line</label>
                        </TableCell>
                        <TableCell
                          sx={{ fontSize: "0.60rem", textAlign: "center" }}
                        >
                          <label style={labelStyles}>
                            {gameData?.["h-ml"]}
                          </label>
                          <br />
                          <label>{gameData?.["h-ml-points"]} Pts</label>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ fontSize: "0.60rem", textAlign: "center" }}
                        >
                          <label style={labelStyles}>
                            {gameData?.["v-sprd"]}
                          </label>
                          <br />
                          <label>{gameData?.["v-sprd-points"]} Pts</label>
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "0.60rem",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          <label>Spread</label>
                        </TableCell>
                        <TableCell
                          sx={{ fontSize: "0.60rem", textAlign: "center" }}
                        >
                          <label style={labelStyles}>
                            {gameData?.["h-sprd"]}
                          </label>
                          <br />
                          <label>{gameData?.["h-sprd-points"]} Pts</label>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ fontSize: "0.60rem", textAlign: "center" }}
                        >
                          <label style={labelStyles}>
                            {gameData?.["v-ou"]}
                          </label>
                          <br />
                          <label>{gameData?.["v-ou-points"]} Pts</label>
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "0.60rem",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          <label>Over/Under</label>
                        </TableCell>
                        <TableCell
                          sx={{ fontSize: "0.60rem", textAlign: "center" }}
                        >
                          <label style={labelStyles}>
                            {gameData?.["h-ou"]}
                          </label>
                          <br />
                          <label>{gameData?.["h-ou-points"]} Pts</label>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Collapse>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        contentLabel="Edit Game Data"
      >
        <h2>Edit Game Data</h2>
        <form>
          <input
            type="text"
            value={editedGameData?.visitor}
            onChange={(e) =>
              setEditedGameData({ ...editedGameData, visitor: e.target.value })
            }
          />
          <button onClick={handleSaveEdit}>Save</button>
          <button onClick={handleModalClose}>Cancel</button>
        </form>
      </Modal>
    </div>
  );
};

export default MobileCard;
